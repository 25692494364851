<template>
  <card class="card" v-bind="$attrs">
    <form @submit.prevent>
      <div class="row">
        <div class="col-md-12">
          <fg-input
            cy-id="name"
            type="text"
            label="Nombre completo"
            placeholder="Ejemplo: Juan Perez"
            v-model.trim="$v.user.name.$model"
            :is-valid="!$v.user.name.$error"
            :errorMessage="'Nombre de usuario inválido'"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <fg-input
            cy-id="cuit"
            type="text"
            label="CUIT"
            placeholder="Ejemplo: 20292181949"
            v-model.trim="$v.user.cuit.$model"
            :is-valid="!$v.user.cuit.$error"
            :errorMessage="'Cuit inválido'"
          />
        </div>
        <div class="col-md-4">
          <fg-input
            cy-id="phone"
            type="text"
            label="Telefono"
            placeholder="Ejemplo: 3415942940"
            v-model.trim="$v.user.cell.$model"
            :is-valid="!$v.user.cell.$error"
            :errorMessage="'Numero de teléfono inválido'"
          />
        </div>
        <div class="col-md-4">
          <fg-input
            cy-id="email"
            type="text"
            label="Email"
            placeholder="Ejemplo: emailejemplo@gmail.com"
            v-model.trim="$v.user.email.$model"
            :is-valid="!$v.user.email.$error"
            :error-message="'Email inválido. Ingrese una dirección de email correcta.'"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <fg-input
            cy-id="address"
            type="text"
            label="Dirección"
            placeholder="Ejemplo: Italia 1931"
            v-model.trim="$v.user.address.$model"
            :is-valid="!$v.user.address.$error"
            :error-message="'Dirección inválida'"
          />
        </div>
        <div class="col-md-4">
          <fg-input
            cy-id="city"
            type="text"
            label="Localidad"
            placeholder="Ejemplo: Maipú"
            v-model.trim="$v.user.location.$model"
            :is-valid="!$v.user.location.$error"
            :error-message="'Localidad inválida'"
          />
        </div>
        <div class="col-md-4">
          <fg-input
            cy-id="state"
            type="text"
            label="Provincia"
            placeholder="Ejemplo: Santa fe"
            v-model.trim="$v.user.province.$model"
            :is-valid="!$v.user.province.$error"
            :error-message="'Provincia inválida'"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <fg-input
            cy-id="office"
            type="text"
            label="Oficina"
            placeholder="Ejemplo: Rosario"
            v-model.trim="$v.user.office.$model"
            :is-valid="!$v.user.office.$error"
            :error-message="'Ingrese alguna oficina'"
          />
        </div>
        <div class="col-md-6">
          <fg-input
            cy-id="intern"
            type="number"
            label="Nro. interno"
            placeholder="Ejemplo: 1"
            v-model.trim="$v.user.fileNumber.$model"
            :is-valid="!$v.user.fileNumber.$error"
            :error-message="'Ingrese algún Nro. Interno'"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <fg-input
            cy-id="sancor-id"
            type="text"
            label="Nro. productor SanCor"
            placeholder="Ejemplo: J1435684310"
            v-model.trim="$v.user.sancorId.$model"
            :is-valid="!$v.user.sancorId.$error"
            :error-message="'Nro. productor SanCor es requerido'"
          />
        </div>
        <div class="col-md-6">
          <div class="form-group" v-if="isAdmin || isSuperUser">
            <label>Tipo de productor</label>
            <select cy-id="producer" class="custom-select" v-model="user.type">
              <option v-for="(opt, i) in userFormOptions" :value="opt.value" :key="i">
                {{ opt.field }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="row" v-if="isSupervisor || isAdmin || isSuperUser">
        <div class="col-md-4">
          <fg-input
            cy-id="user-name"
            type="text"
            label="Nombre de usuario"
            placeholder="Ejemplo: JulioM10"
            v-model.trim="$v.user.username.$model"
            :is-valid="!$v.user.username.$error"
            :error-message="'Nombre de usuario es requerido'"
          />
        </div>
        <div class="col-md-4">
          <fg-input
            cy-id="password"
            v-if="password"
            type="password"
            label="Clave"
            placeholder="Clave"
            v-model.trim="$v.user.password.$model"
            :is-valid="!$v.user.password.$error"
            :error-message="'Ingrese alguna contraseña'"
          />
        </div>
        <div class="col-md-4">
          <fg-input
            cy-id="confirm-password"
            v-if="password"
            type="password"
            label="Confirmar clave"
            placeholder="Confirmar clave"
            v-model.trim="$v.user.password_confirmation.$model"
            :is-valid="!$v.user.password_confirmation.$error"
            :error-message="'Ingrese la misma contraseña para confirmarla'"
          />
        </div>
      </div>

      <div class="row" v-if="isAdmin || isSuperUser">
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Permite pago en especie</label>
            <div>
              <p-switch cy-id="species-payment" v-model="user.allowPaymentInKind" type="primary"></p-switch>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Permite descuentos en packs</label>
            <div>
              <p-switch v-model="user.allowDiscountsOnPacks" type="primary"></p-switch>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Cotización con ponderación</label>
            <div>
              <p-switch v-model="user.allowSplitCoverages" type="primary"></p-switch>
            </div>
          </div>
        </div>
      </div>

      <div class="row" v-if="(isAdmin || isSuperUser) && !isUserAdmin">
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Es supervisor</label>
            <div>
              <p-switch cy-id="supervisor" v-model="supervisor" type="primary"></p-switch>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Permitir pasar a propuesta</label>
            <div>
              <p-switch v-model="user.sendProposal" type="primary"></p-switch>
            </div>
          </div>
        </div>
        <div class="col-md-4" v-if="isAdmin">
          <div class="form-group">
            <label class="control-label">Permitir ver simulador</label>
            <div>
              <p-switch cy-id="see-simulator" v-model="user.seeSimulator" type="primary"></p-switch>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Permitir ver videos</label>
            <div>
              <p-switch cy-id="see-video" v-model="user.seeVideo" type="primary"></p-switch>
            </div>
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Ver descriptivo de coberturas</label>
            <div>
              <p-switch cy-id="see-coverage" v-model="user.seePdfDescription" type="primary"></p-switch>
            </div>
          </div>
        </div>

        <div class="col-md-4" v-if="isAdmin || isSuperUser">
          <div class="form-group">
            <label class="control-label">Permitir coberturas personalizadas</label>
            <div>
              <p-switch v-model="user.seeCoverageUserModule" type="primary"></p-switch>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6" v-if="isSupervisor || isAdmin || isSuperUser">
          <fg-input
            cy-id="discount"
            type="number"
            label="Valor maximo de descuento (%)"
            v-model="$v.user.maxDiscountAllow.$model"
            :is-valid="!$v.user.maxDiscountAllow.$error"
            :error-message="'Ingrese un valor máximo de descuento'"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label>Pagina inicial</label>
            <select class="custom-select" v-model="user.preferredUrl">
              <option value="">Página por defecto</option>
              <option value="cotizaciones">Cotizaciones</option>
              <option value="nueva cotizacion">Nueva Cotización</option>
              <option value="solicitudes">Solicitudes</option>
              <option value="nueva solicitud">Nueva Solicitud</option>
              <option value="perfil">Perfil</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <label>Forma de pago por defecto</label>
            <select class="custom-select" v-model="user.paymentMethod" @change="setRateOptions()">
              <option v-for="pm in availablePaymentMethods" :key="pm.code" :value="pm.code">
                {{ pm.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="form-group" v-show="user.paymentMethod">
            <label>Opción de tasa por defecto</label>
            <select class="custom-select" v-model="user.rateOption">
              <option v-for="ro in availableRateOptions" :key="ro.code" :value="ro.code">
                {{ ro.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <label>Unidad de pago por defecto</label>
          <select class="custom-select" v-model="user.hectarePriceCurrency">
            <option :value="constant.PESO">$</option>
            <option :value="constant.QUINTAL">QQ</option>
            <option :value="constant.DOLLAR">USD</option>
          </select>
        </div>
      </div>
      <div class="flex-button-container">
        <p-button cy-id="save" type="primary" @click.native.prevent="submitForm">Guardar productor</p-button>
      </div>
      <div class="clearfix"></div>
    </form>
  </card>
</template>
<script>
import PSwitch from '@/components/Switch.vue';
import granStoredPortjs from '@/components/Forms/FormProposal/granStoredPort.js';
import { isAdmin, isSuperUser, isSupervisor } from '@/plugins/roles.js';
import { userFormOptions } from '@/utils/users.js';
import { required, sameAs, numeric, email } from 'vuelidate/lib/validators';
import { validateAlpha, validateCuit } from '@/utils/validators.js';
import { scrollToTop } from '@/utils/general';
import constants from '@/plugins/constants.js';
import paymentMethods from '@/plugins/paymentMethods.js';

const granStored = granStoredPortjs;

export default {
  name: 'UserForm',
  components: {
    PSwitch
  },
  props: {
    user: {
      type: Object,
      default: () => ({
        name: '',
        cuit: '',
        address: '',
        location: '',
        province: '',
        office: '',
        fileNumber: '',
        sancorId: '',
        type: '',
        cell: '',
        email: '',
        username: '',
        password: '',
        password_confirmation: '',
        role_id: 4,
        allowPaymentinKind: false,
        allowDiscountsOnPacks: false,
        allowSplitCoverages: false,
        seeVideo: false,
        seePdfDescription: false,
        seeSimulator: false,
        preferredUrl: '',
        seeCoverageUserModule: false,
        sendProposal: false,
        exchangeStoreId: '',
        maxDiscountAllow: 0,
        paymentMethod: null,
        rateOption: null,
        hectarePriceCurrency: null
      })
    },

    password: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isUsernameValid: true,
      isEmailValid: true,
      isPasswordValid: true,
      hasErrors: false,
      stores: [],
      errorMessages: [],
      errorMessage: '',
      constant: constants,
      userFormOptions
    };
  },
  created() {
    this.stores = granStored;
  },
  validations() {
    const validationsRules = {
      user: {
        name: {
          required,
          validateAlpha
        },
        cuit: {
          required,
          validateCuit,
          numeric
        },
        address: {
          required,
          validateAlpha
        },
        location: {
          required,
          validateAlpha
        },
        province: {
          required,
          validateAlpha
        },
        office: {
          required
        },
        fileNumber: {
          required
        },
        sancorId: {
          required,
          validateAlpha
        },
        cell: {
          lengthValidator(valueLength) {
            return valueLength.length === 10;
          },
          numeric
        },
        email: {
          required,
          email
        },
        username: {
          required
        },
        maxDiscountAllow: {
          required
        }
      }
    };

    if (this.password) {
      validationsRules.user.password = {
        required
      };
      validationsRules.user.password_confirmation = {
        required,
        sameAsPassword: sameAs('password')
      };
    }
    return validationsRules;
  },
  methods: {
    setRateOptions() {
      this.user.rateOption = null;
    },
    submitForm() {
      if (!this.isAdmin) {
        if (this.isSupervisor) {
          this.user.roles = ['ROLE_SUPERVISOR'];
        } else {
          this.user.roles = ['ROLE_USER'];
        }
      }
      if (this.validate()) {
        this.$emit('submit');
      } else {
        scrollToTop('.text-invalid');
      }
    },
    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    selectGranStored(store) {
      this.user.exchangeStoreId = store.value;
    }
  },

  computed: {
    isAdmin() {
      return isAdmin(this.$auth.user()) || isSuperUser(this.$auth.user());
    },
    isSupervisor() {
      return isSupervisor(this.$auth.user()) || isAdmin(this.$auth.user()) || isSuperUser(this.$auth.user());
    },
    isSuperUser() {
      return isSuperUser(this.$auth.user());
    },
    isUserAdmin() {
      return isAdmin(this.user);
    },
    supervisor: {
      get() {
        return isSupervisor(this.user);
      },
      set(newValue) {
        if (this.isUserAdmin) {
          return;
        }
        if (newValue) {
          this.user.roleId = 3;
        } else {
          this.user.roleId = 4;
        }
      }
    },
    storedOptions() {
      return this.stores
        .map(gs => ({
          label: gs.description,
          value: gs.id
        }))
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
    },
    userStore() {
      let store = this.stores.filter(store => this.$props.user.exchangeStorageId == store.id);

      if (store && store.length === 1) {
        return {
          label: store[0].description,
          value: store[0].id
        };
      }
      return null;
    },
    availablePaymentMethods() {
      let apm = [];
      let pms = [paymentMethods.EXCHANGE_LABEL, paymentMethods.FINANCED_LABEL];
      if (this.pagoEnEspecieDisponible) {
        pms.push(paymentMethods.KIND_LABEL);
        pms.push(paymentMethods.FINANCED_KIND_LABEL);
      }
      apm = paymentMethods.METHODS.filter(pm => pms.includes(pm.label));
      return apm;
    },
    availableRateOptions() {
      if (this.user.paymentMethod !== 0) {
        return paymentMethods.METHODS.find(pm => pm.code === this.user.paymentMethod)?.rateOptions ?? [];
      }
      return [];
    },
    isCanjeAbierto() {
      return (
        (this.currency === constants.PESO || this.currency === constants.DOLLAR) &&
        this.user.paymentMethod === paymentMethods.EXCHANGE
      );
    },
    pagoEnEspecieDisponible() {
      if (isAdmin(this.$auth.user()) || isSuperUser(this.$auth.user())) {
        return true;
      }
      if (this.$auth.user().allow_payment_in_kind !== undefined && this.$auth.user().allow_payment_in_kind === 1) {
        return true;
      } else {
        return false;
      }
    },

    pagoEnEspecieSeleccionado() {
      return this.pagoEnEspecieDisponible && this.user.paymentMethod === paymentMethods.KIND;
    }
  }
};
</script>
