<template>
  <EntityTable
    cy-id="quotation-table"
    class="negotiation-table"
    :columns="columns"
    :tableData="tableData"
    :routeName="routeName"
    type="hover"
    :total="total"
  >
    <template slot="filters">
      <div class="d-flex">
        <fg-input
          cy-id="search"
          type="text"
          label="Buscar cotización"
          placeholder="Buscar"
          class="search-input"
          v-model="params.search"
        />
      </div>
    </template>
    <template slot-scope="{ row }" v-if="tableData.length > 0">
      <td cy-id="negotiation-id">
        <strong>{{ isSimpleNegotiation ? row.application_number : row.number }}</strong>
      </td>
      <td>{{ row.createdAt | formatoFecha }}</td>
      <td>{{ row.user.name }}</td>
      <td>
        <span>{{ row.client.name }}</span>
        <span v-if="row.client.lastname">&nbsp;{{ row.client.lastname }}</span>
      </td>
      <td>
        <div v-for="risk in row.risks" :key="risk.id">
          <p class="my-2">
            <span>{{ risk.zone.description }} - {{ risk.crop.description }}</span>
            <br />
            <span>
              {{ risk.numberOfHectares }} HA - {{ risk.hectarePrice }}
              {{ risk.hectarePriceCurrency | unidadValorHA }}
            </span>
            <br />
            <span v-if="!isSimpleNegotiation">Descuento: {{ risk.discount }}%</span>
          </p>
        </div>
      </td>
      <td v-if="!isSimpleNegotiation">
        <p class="my-2">
          {{ row.paymentMethodId | formaPago }}
          <br />
          {{ row.rateOptionId | opcionTasa }}
        </p>
      </td>

      <td>
        <router-link
          style="margin-right: 10px"
          v-if="hasSimulation(row) && hasPermissionSimulation"
          :to="{ name: 'simulador', params: { id: row.id } }"
          class="btn btn-xs btn-info"
        >
          <i class="ti-arrow-top-right" style="vertical-align: middle; margin-right: 3px"></i>
          Simular
        </router-link>

        <router-link
          cy-id="see-negotiation"
          v-if="isSimpleNegotiation"
          :to="{ name: 'ver solicitud', params: { id: row.id } }"
          class="btn btn-xs btn-info"
        >
          <i class="ti-eye mr-1" style="vertical-align: middle; margin-right: 3px"></i>
          Ver
        </router-link>
        <router-link
          cy-id="see-negotiation"
          v-else
          :to="{ name: 'ver cotizacion', params: { id: row.id } }"
          class="btn btn-xs btn-info"
        >
          <i class="ti-eye mr-1"></i>
          Ver
        </router-link>
        <router-link
          cy-id="quote-negotiation"
          v-if="isSimpleNegotiation"
          :to="{ name: 'editar cotizacion', params: { id: row.id } }"
          class="btn btn-xs btn-primary"
        >
          <i class="ti-pencil mr-1" style="vertical-align: middle; margin-right: 3px"></i>
          Cotizar
        </router-link>
      </td>
    </template>
    <template slot="pagination">
      <div class="pagination-size">
        <div class="form-group">
          <label class="mr-2">Mostrar por página:</label>
          <select class="custom-select" v-model="params.perPage">
            <option v-for="ppo in perPageOptions" :value="ppo" :key="ppo">
              {{ ppo }}
            </option>
          </select>
        </div>
      </div>
      <Pagination class="pull-right" v-model="params.page" :per-page="params.perPage" :total="total"></Pagination>
    </template>
  </EntityTable>
</template>

<script>
import EntityTable from '@/components/EntityTable';
import Pagination from '@/components/Pagination';
import { CROP_IDS } from '@/utils/general';

export default {
  components: {
    EntityTable,
    Pagination
  },

  props: {
    columns: Array,
    tableData: Array,
    type: {
      type: String, // striped | hover
      default: 'striped'
    },
    routeName: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    total: {
      type: Number,
      required: true
    },
    isSimpleNegotiation: {
      type: Boolean,
      default: false
    },
    hasPermissionSimulation: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      perPageOptions: [5, 10, 25, 50],
      params: {
        search: '',
        perPage: 10,
        page: 1
      },
      simpleNegotiationColumns: ['#', 'Fecha', 'Productor', 'Cliente', 'Zona y Cultivo - Riesgo'],
      cropIdsThatHaveSimulation: [CROP_IDS['soy'], CROP_IDS['wheat'], CROP_IDS['corn']]
    };
  },

  computed: {
    tableClass() {
      return `table-${this.type}`;
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },

    from() {
      return this.pagination.perPage * (this.pagination.page - 1);
    }
  },

  methods: {
    hasSimulation(row) {
      for (let i = 0; i < row.risks.length; i++) {
        if (this.cropIdsThatHaveSimulation.includes(row.risks[i].cropId)) {
          return true;
        }
      }
      return false;
    },

    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },

    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    refetch() {
      this.$emit('refetch', {
        'filter[search]': this.params.search,
        perPage: this.params.perPage,
        page: this.params.page
      });
    }
  },

  watch: {
    'params.search': {
      handler(newValue) {
        setTimeout(() => {
          if (newValue === this.params.search) {
            this.refetch();
          }
        }, 1200);
      },
      deep: true
    },
    'params.perPage': {
      handler() {
        this.refetch();
      },
      deep: true
    },
    'params.page': {
      handler() {
        this.refetch();
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .negotiation-table {
  td {
    p {
      font-size: 14px;
    }
  }

  @media only screen and (min-width: 990px) and (max-width: 1200px) {
    width: auto;
    overflow-x: scroll;

    td {
      white-space: nowrap;
    }
  }
}
</style>
