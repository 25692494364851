<template>
  <card class="d-flex p-3">
    <div class="form-field d-inline">
      <div class="d-inline-block">
        <label class="font-weight-bold">Capital ($)</label>
        <fg-input
          type="number"
          placeholder="$1.000.000"
          v-model.trim="$v.capital.$model"
          :is-valid="!$v.capital.$error"
          :errorMessage="'Capital inválido'"
        ></fg-input>
      </div>
    </div>
    <div>
      <label class="font-weight-bold">Cultivos</label>
      <div>
        <Checkbox v-model="crops.soy"><span>Soja</span></Checkbox>
      </div>
      <crops-parameters-form
        class="mb-3"
        v-if="crops.soy"
        :fillData="fillData"
        :exists="existsSoy"
        :capital="capital"
        @addData="fillSoyData"
        @deleteData="deleteSoyData"
      />
      <hr class="mb-3" />
      <div>
        <Checkbox v-model="crops.corn"><span>Maíz</span></Checkbox>
      </div>
      <crops-parameters-form
        class="mb-3"
        v-if="crops.corn"
        :fillData="fillData"
        :capital="capital"
        :exists="existsCorn"
        @addData="fillCornData"
        @deleteData="deleteCornData"
      />
      <hr class="mb-3" />
      <div>
        <Checkbox v-model="crops.wheat"><span>Trigo</span></Checkbox>
      </div>
      <crops-parameters-form
        class="mb-3"
        v-if="crops.wheat"
        :capital="capital"
        :fillData="fillData"
        :exists="existsWheat"
        @addData="fillWheatData"
        @deleteData="deleteWheatData"
      />
    </div>
    <div class="d-flex justify-content-end">
      <div v-if="crops.soy || crops.corn || crops.wheat" class="button-container">
        <button class="btn btn-info" @click="simulate()">Aceptar</button>
      </div>
      <div v-if="calculatedData" class="button-container">
        <button class="btn btn-danger" @click="downloadPDF()">Descargar PDF</button>
        <button class="btn btn-success" @click="downloadExcel()">Descargar Excel</button>
      </div>
    </div>
    <div v-for="crop in calculatedData" :key="crop.id">
      <div class="container shadow-lg p-4 my-2 bg-light rounded">
        <crop-data :capital="capital" :calculatedData="crop" />
        <summary-table class="p-4" :calculatedData="crop" />
      </div>
    </div>
  </card>
</template>

<script>
import CropData from '@/components/Refinancing/CropData';
import SummaryTable from '@/components/Refinancing/SummaryTable';
import CropsParametersForm from '@/components/Refinancing/CropsParametersForm';
import { downloadBlob } from '@/plugins/files.js';
import { Checkbox } from 'element-ui';
import { CROP_IDS } from '@/utils/general.js';
import { required, numeric } from 'vuelidate/lib/validators';

export default {
  components: {
    CropData,
    SummaryTable,
    CropsParametersForm,
    Checkbox
  },
  props: {
    negotiationId: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      params: {},
      crops: {
        soy: false,
        corn: false,
        wheat: false
      },
      capital: null,
      parameters: [],
      fillData: false,
      existsSoy: false,
      existsWheat: false,
      existsCorn: false,
      calculatedData: null,
      errorMessage: ''
    };
  },

  validations: {
    capital: {
      required,
      numeric
    }
  },

  methods: {
    successToast(message) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
        type: 'success'
      });

      Toast.fire({
        title: message
      });
    },

    errorToast(message) {
      const Toast = this.$swal.mixin({
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
        type: 'error'
      });

      Toast.fire({
        title: message
      });
    },

    fillSoyData(params) {
      this.parameters.push({ cropId: CROP_IDS.soy, ...params });
      this.existsSoy = true;
      this.successToast('Se ha agregado el cultivo.');
    },

    fillCornData(params) {
      this.parameters.push({ cropId: CROP_IDS.corn, ...params });
      this.existsCorn = true;
      this.successToast('Se ha agregado el cultivo.');
    },

    fillWheatData(params) {
      this.parameters.push({ cropId: CROP_IDS.wheat, ...params });
      this.existsWheat = true;
      this.successToast('Se ha agregado el cultivo.');
    },

    deleteSoyData() {
      const param = this.parameters.find(e => e.cropId == CROP_IDS.soy);
      const i = this.parameters.indexOf(param);
      this.existsSoy = false;

      this.parameters.splice(i, 1);
      this.successToast('Se ha eliminado el cultivo.');
    },

    deleteWheatData() {
      const param = this.parameters.find(e => e.cropId == CROP_IDS.wheat);
      const i = this.parameters.indexOf(param);
      this.existsWheat = false;

      this.parameters.splice(i, 1);
      this.successToast('Se ha eliminado el cultivo.');
    },

    deleteCornData() {
      const param = this.parameters.find(e => e.cropId == CROP_IDS.corn);
      const i = this.parameters.indexOf(param);
      this.existsCorn = false;

      this.parameters.splice(i, 1);
      this.successToast('Se ha eliminado el cultivo.');
    },

    simulate() {
      if (this.validate() && this.parameters.length != 0) {
        const payload = { capital: this.capital, crops: this.parameters, negotiationId: this.negotiationId };
        this.$api.refinance.getRefinance(payload).then(response => {
          this.calculatedData = response;
        });
        (this.crops.soy = false), (this.crops.wheat = false), (this.crops.corn = false);
      } else {
        this.errorToast('Revise los datos ingresados.');
      }
    },

    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },

    downloadPDF() {
      const payload = { capital: this.capital, crops: this.parameters, negotiationId: this.negotiationId };
      this.$api.refinance
        .getPdf(payload, this.negotiationId)
        .then(response => {
          const fileName = `Financiación.pdf`;
          downloadBlob(new Blob([response]), fileName);
        })
        .catch(error => {
          console.error(error);
        });
    },
    downloadExcel() {
      const payload = { capital: this.capital, crops: this.parameters, negotiationId: this.negotiationId };
      this.$api.refinance
        .getExcel(payload)
        .then(response => {
          const fileName = `Financiación.xlsx`;
          downloadBlob(new Blob([response]), fileName);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>

<style scoped>
.container {
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.button-container .btn {
  margin: 10px;
}

span {
  font-size: 16px;
}

::v-deep .el-checkbox__inner {
  background: #e8e8e8;
  border-color: rgb(148, 148, 148);
}

@media (max-width: 600px) {
  .button-container {
    display: flex;
    flex-direction: column;
  }

  .button-container .btn {
    width: 100%;
    margin: 10px 0;
  }
}
</style>
