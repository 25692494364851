<template>
  <card>
    <div>
      <div slot="raw-content" class="table-responsive">
        <entity-table
          :table-data="risks"
          :total="risks.length"
          :has-actions="false"
          :has-search="false"
          :has-pagination="false"
        >
          <template #columns>
            <th v-for="column in columns()" :key="column.name" :class="column.class">
              {{ column.name }}
            </th>
          </template>
          <template slot-scope="{ row }">
            <td>
              <strong>{{ row.crop }}</strong>
            </td>
            <!--cultivo-->

            <td>{{ row.hectares | numberToCurrency }}</td>
            <!--hectareas-->

            <td>{{ row.hectarePrice | numberToCurrency }} {{ row.hectarePriceCurrency | currencySymbol }}/HA</td>
            <!--suma asegurada-->

            <td>{{ row.zone }}</td>
            <!--departamento-->

            <td>{{ row.quotation.rate.module.description }}</td>
            <!--cobertura-->

            <td>{{ row.quotation.rate.franchiseDeductible }}</td>
            <!--franquicia/deducible-->

            <td>{{ row.calculatedQuotation.quotation_value.toFixed(2) | numberToCurrency }}%</td>
            <!--tasa sin iva-->

            <td>{{ row.calculatedQuotation.rechargeRate | numberToCurrency }}%</td>
            <!--tasa recargo-->

            <td>{{ row.calculatedQuotation.rateWithRecharge.toFixed(2) | numberToCurrency }}%</td>
            <!--tasa con recargo (tasa sin iva + (1 * tasa recargo))-->

            <td v-if="row.hectarePriceCurrency === 'dollar'">
              {{ row.calculatedQuotation.dollarToday.toFixed(2) | numberToCurrency }}
            </td>
            <!--cotizacion del día del dólar-->

            <td v-else>
              {{ row.calculatedQuotation.boardQuotation.toFixed(2) | numberToCurrency }}
            </td>
            <!--cotizacion pizarra-->

            <td class="d-none">{{ row.calculatedQuotation.dailyCropQuot.toFixed(2) | numberToCurrency }}</td>
            <!--cot cultivo u$s-->

            <td>
              {{ row.calculatedQuotation.pesifyAmount.toFixed(2) | numberToCurrency }}
            </td>
            <!--monto a pesificar-->

            <td>{{ row.calculatedQuotation.tax.toFixed(2) | numberToCurrency }}</td>
            <!--iva-->

            <td>
              {{ row.calculatedQuotation.taxPerception.toFixed(2) | numberToCurrency }}
            </td>
            <!--Percepcion iva-->
          </template>
        </entity-table>
      </div>
      <hr />
      <div slot="raw-content" class="table-responsive">
        <entity-table
          :columns="columnsTwo"
          :table-data="rows"
          :total="rows.length"
          :has-actions="false"
          :has-search="false"
          :has-pagination="false"
        >
          <template slot-scope="{ row }">
            <td>
              <strong>{{ row.crop }}</strong>
            </td>
            <td>
              <strong>{{ row.module }}</strong>
            </td>
            <td>{{ row.pesifyAmount.toFixed(2) | numberToCurrency }}</td>
            <td>{{ row.tax.toFixed(2) | numberToCurrency }}</td>
            <td>{{ row.taxPerception.toFixed(2) | numberToCurrency }}</td>
          </template>
        </entity-table>
      </div>
    </div>
  </card>
</template>
<script>
import EntityTable from '@/components/EntityTable';

const tableColumns = [
  { name: 'Cultivo' },
  { name: 'Hectáreas' },
  { name: 'Suma asegurada por hectárea' },
  { name: 'Departamento' },
  { name: 'Cobertura' },
  { name: 'Franquicia/Deducible' },
  { name: 'Tasa sin IVA' },
  { name: 'Tasa recargo' },
  { name: 'Tasa con recargo' },
  { name: 'Cotización Dólar hoy U$S', currency: 'dollar', class: 'd-print-none' },
  { name: 'Cotización Pizarra $/QQ', currency: 'quintal', class: 'd-print-none' },
  { name: 'Cotización cultivo U$S (hoy)', class: 'd-none' },
  { name: 'Monto a pesificar' },
  { name: 'IVA $' },
  { name: 'Percepción IVA' }
];

const tableColumnsTwo = ['Cultivo', 'Cobertura', 'Monto a pesificar $', 'IVA $', 'Percepción IVA $'];

export default {
  components: {
    EntityTable
  },

  props: {
    risks: {
      required: true,
      type: Array
    },

    summarized: {
      required: true,
      type: Object
    }
  },

  filters: {
    numberToCurrency(value) {
      if (!value) return '0.00';
      const intPart = Math.trunc(value);
      const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
      let floatPart = '.00';
      const valueArray = value.toString().split('.');
      if (valueArray.length === 2) {
        floatPart = valueArray[1].toString();
        return intPartFormat + '.' + floatPart;
      }
      return intPartFormat + floatPart;
    }
  },

  data() {
    return {
      currency: '',
      columnsTwo: tableColumnsTwo
    };
  },

  methods: {
    columns() {
      const [{ hectarePriceCurrency: currency }] = this.risks;
      this.currency = currency;
      if (this.currency === 'dollar') {
        return tableColumns.filter(col => {
          return col.currency != 'quintal';
        });
      }
      return tableColumns.filter(col => {
        return col.currency != 'dollar';
      });
    }
  },

  computed: {
    rows() {
      let data = [];
      for (const modulo in this.summarized) {
        for (const fod in this.summarized[modulo]) {
          data.push({
            module: `${modulo} - ${fod}`,
            ...this.summarized[modulo][fod]
          });
        }
      }
      return data;
    }
  }
};
</script>

<style scoped>
.table-responsive {
  overflow-x: auto;
}
</style>
