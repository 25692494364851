<template>
  <card>
    <div>
      <button
        v-if="filteredQuotations.length"
        class="btn p-2"
        @click="filter"
        :class="[hideQuotations ? 'btn-primary' : 'btn-danger']"
      >
        {{ hideQuotations ? 'Mostrar Cotizaciones' : 'Filtrar Cotizaciones' }}
      </button>
    </div>
    <div>
      <div slot="raw-content" class="table-responsive">
        <entity-table
          :table-data="filteredItems"
          :total="filteredItems.length"
          :has-actions="false"
          :has-search="false"
          :has-pagination="false"
        >
          <template #columns>
            <th v-for="column in columns()" :key="column.name" :class="column.class">
              {{ column.name }}
            </th>
          </template>
          <template slot-scope="{ index, row }">
            <td class="text-center">
              <input
                type="checkbox"
                class=""
                style="width: 1rem; height: 1rem; accent-color: #3091b2"
                :disabled="hideQuotations"
                :checked="filteredQuotations.includes(row.quotation.id)"
                @change="selectFilter(row.quotation.id, $event)"
              />
            </td>
            <td>
              <strong>{{ row.crop }}</strong>
            </td>
            <!--cultivo-->

             <td>
              <strong>{{ row.quotation.rate.module.description }}</strong>
            </td>
            <!--cobertura-->

            <td>{{ row.hectares | numberToCurrency }}</td>
            <!--hectareas-->

            <td>{{ row.hectarePrice | numberToCurrency }} {{ row.hectarePriceCurrency | currencySymbol }}/HA</td>
            <!--suma asegurada-->

            <td>{{ row.zone }}</td>
            <!--departamento-->

            <td>{{ row.calculatedQuotation.quotation_value.toFixed(2) | numberToCurrency }}%</td>
            <!--premio % sin iva sin recargo-->

            <td>{{ row.calculatedQuotation.rateWithRecharge.toFixed(2) | numberToCurrency }}%</td>
            <!--premio % sin iva con recargo-->

            <td v-if="row.hectarePriceCurrency === 'dollar'">
              {{ row.calculatedQuotation.dollarToday.toFixed(2) | numberToCurrency }}
            </td>
            <!--cotizacion del día del dólar-->

            <td v-else>
              {{ row.calculatedQuotation.boardQuotation.toFixed(2) | numberToCurrency }}
            </td>
            <!--cotizacion pizarra-->

            <td v-if="row.hectarePriceCurrency === 'dollar'" class="d-print-none">
              {{ row.calculatedQuotation.futureDollar.toFixed(2) | numberToCurrency }}
            </td>
            <!--cotizacion del dólar futuro-->

            <td v-else class="d-print-none">
              {{ row.calculatedQuotation.boardQuotationFuture.toFixed(2) | numberToCurrency }}
            </td>
            <!--cotización futuro $/QQ ó cotización grano/ u$s a futuro rofex-->

            <td>{{ row.calculatedQuotation.prizeWithoutTaxFuture.toFixed(2) | numberToCurrency }}%</td>
            <!--Tasa sin IVA a valor futuro-->

            <td>{{ row.calculatedQuotation.benefitObtained.toFixed(2) | numberToCurrency }}%</td>
            <!--Beneficio neto obtenido-->

            <td>
              {{ row.calculatedQuotation.costPrizeWithoutTax.toFixed(2) | numberToCurrency }}
              {{ row.hectarePriceCurrency | currencySymbol }}
            </td>
            <!--Costo en QQ/U$S a premio sin IVA sin recargo-->

            <td>
              {{ row.calculatedQuotation.costPlus.toFixed(2) | numberToCurrency }}
              {{ row.hectarePriceCurrency | currencySymbol }}
            </td>
            <!--Costo en QQ/U$S a valor futuro-->

            <td class="d-print-none">
              {{ row.calculatedQuotation.difference.toFixed(2) }}
            </td>
            <!--Diferencia en QQ-->

            <td>{{ row.calculatedQuotation.savingsWithIncomeTax.toFixed(2) | numberToCurrency }}%</td>
            <!--Ahorro impuesto a las ganancias %-->

            <td>
              {{ row.calculatedQuotation.savingsOnOriginalCurrency.toFixed(2) | numberToCurrency }}
            </td>
            <!--Ahorro en moneda de Origen (qq/u$S)-->

            <td>{{ row.calculatedQuotation.rateWithoutTaxWithIncomeTax.toFixed(2) | numberToCurrency }}%</td>
            <!--Tasa sin iva con impacto impuesto a las ganancias -->

            <td>{{ row.calculatedQuotation.totalSavings.toFixed(2) | numberToCurrency }}</td>
            <!--Ahorro Total ( considerando Imp a las ganancias)  -->
          </template>
        </entity-table>
      </div>
    </div>
  </card>
</template>
<script>
import EntityTable from '@/components/EntityTable';

const tableColumns = [
  { name: '' },
  { name: 'Cultivo' },
  { name: 'Cobertura' },
  { name: 'Hectáreas' },
  { name: 'Suma asegurada por hectárea' },
  { name: 'Departamento' },
  { name: 'Premio % sin IVA sin recargo' },
  { name: 'Premio % sin IVA con recargo' },
  { name: 'Cotización Dólar hoy U$S', currency: 'dollar', class: 'd-print-none' },
  { name: 'Cotización Pizarra $/QQ', currency: 'quintal', class: 'd-print-none' },
  { name: 'Cotización Grano/ U$S a futuro ROFEX', class: 'd-print-none' },
  { name: 'Cotización cultivo U$S (hoy)', class: 'd-none' },
  { name: 'Tasa sin IVA a valor futuro' },
  { name: 'Beneficio neto obtenido (potencial)' },
  { name: 'Costo QQ/U$S premio sin IVA sin recargo' },
  { name: 'Costo QQ/U$S a valor futuro' },
  { name: 'Diferencia en QQ' },
  { name: 'Ahorro impuesto a las ganancias %' },
  { name: 'Ahorro en moneda de Origen (QQ/U$S)' },
  { name: 'Tasa sin IVA con impacto imp. a las ganancias.' },
  { name: 'Ahorro Total  (con imp. a las ganancias.)' }
];

export default {
  components: {
    EntityTable
  },

  props: {
    risks: {
      required: true,
      type: Array
    }
  },

  filters: {
    numberToCurrency(value) {
      if (!value) return '0.00';
      const intPart = Math.trunc(value);
      const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
      let floatPart = '.00';
      const valueArray = value.toString().split('.');
      if (valueArray.length === 2) {
        floatPart = valueArray[1].toString();
        return intPartFormat + '.' + floatPart;
      }
      return intPartFormat + floatPart;
    }
  },

  data() {
    return {
      filteredQuotations: [],
      hideQuotations: false
    };
  },

  computed: {
    rows() {
      let data = [];
      for (const modulo in this.summarized) {
        for (const fod in this.summarized[modulo]) {
          data.push({
            module: `${modulo} - ${fod}`,
            ...this.summarized[modulo][fod]
          });
        }
      }
      return data;
    },
    filteredItems() {
      if (this.hideQuotations) {
        return this.risks.filter(risk => this.filteredQuotations.includes(risk.quotation.id));
      } else {
        return this.risks;
      }
    }
  },

  methods: {
    selectFilter(item, isChecked) {
      if (isChecked) {
        this.filteredQuotations.push(item);
      } else {
        const i = this.filteredQuotations.findIndex(element => element.id === item.id);
        if (i !== -1) {
          this.filteredQuotations.splice(i, 1);
        }
      }
    },
    columns() {
      const [{ hectarePriceCurrency: currency }] = this.risks;
      this.currency = currency;
      if (this.currency === 'dollar') {
        return tableColumns.filter(col => {
          return col.currency != 'quintal';
        });
      }
      return tableColumns.filter(col => {
        return col.currency != 'dollar';
      });
    },
    filter() {
      this.hideQuotations = !this.hideQuotations;
      if (!this.hideQuotations) {
        this.filteredQuotations = [];
      }
      this.$emit('filterQuotations', this.filteredQuotations);
    }
  }
};
</script>

<style scoped>
.table-responsive {
  overflow-x: auto;
}
</style>
