<template>
  <div>
    <form>
      <div class="form-fields">
        <div class="row mb-3">
          <div class="col-md-4">
            <div class="form-field">
              <div class="input-container">
                <label class="font-weight-bold">TNA ($)</label>
                <fg-input
                  :readonly="exists"
                  type="number"
                  placeholder="102%"
                  v-model.trim="$v.params.tnaPesos.$model"
                  :is-valid="!$v.params.tnaPesos.$error"
                  :errorMessage="'TNA inválido'"
                ></fg-input>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-field">
              <div class="input-container">
                <label class="font-weight-bold">TNA (U$S)</label>
                <fg-input
                  :readonly="exists"
                  type="number"
                  placeholder="5%"
                  v-model.trim="$v.params.tnaDollars.$model"
                  :is-valid="!$v.params.tnaDollars.$error"
                  :errorMessage="'TNA inválido'"
                ></fg-input>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-field">
              <div class="input-container">
                <label class="font-weight-bold">Fecha vto. del cultivo</label>
                <fg-input
                  :readonly="exists"
                  type="date"
                  v-model.trim="$v.params.cropExpDate.$model"
                  @change="$v.params.dollarExpDate.$model = $event"
                  :is-valid="!$v.params.cropExpDate.$error"
                  :errorMessage="'Fecha inválida'"
                ></fg-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <div class="form-field">
              <div class="input-container">
                <label class="font-weight-bold">Interés</label>
                <fg-input
                  :readonly="exists"
                  type="number"
                  placeholder="15000"
                  v-model.trim="$v.params.interest.$model"
                  :is-valid="!$v.params.interest.$error"
                  :errorMessage="'Interés inválido'"
                ></fg-input>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-field">
              <div class="input-container">
                <label class="font-weight-bold">IVA</label>
                <fg-input
                  :readonly="exists"
                  type="number"
                  placeholder="1000"
                  v-model.trim="$v.params.iva.$model"
                  :is-valid="!$v.params.iva.$error"
                  :errorMessage="'IVA inválido'"
                ></fg-input>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-field">
              <div class="input-container">
                <label class="font-weight-bold">Fecha vto. del dólar</label>
                <fg-input
                  :readonly="exists"
                  type="date"
                  placeholder=""
                  v-model.trim="$v.params.dollarExpDate.$model"
                  :is-valid="!$v.params.dollarExpDate.$error"
                  :errorMessage="'Fecha inválida'"
                ></fg-input>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3 justify-content-end">
          <div class="col-md-3">
            <button v-if="!exists" class="btn btn-primary btn-block" @click.prevent="submitForm()">Agregar</button>
            <button v-else class="btn btn-danger btn-block" @click.prevent="$emit('deleteData')">Eliminar</button>
          </div>
        </div>
      </div>

      <div class="px-2">
        <div>
          <label class="font-weight-bold">Impuestos</label>
        </div>
        <div class="d-inline-block">
          <Checkbox :disabled="exists" v-model="params.includeProfitTax">Mostrar impuesto a las ganancias</Checkbox>
          <Checkbox :disabled="exists" v-model="params.includeIvaTax">Mostrar IVA</Checkbox>
        </div>
      </div>
      <div class="px-2">
        <div>
          <label class="font-weight-bold">Analisis de dolares para pesos</label>
        </div>
        <div class="d-inline-block">
          <Checkbox :disabled="exists" v-model="params.includeDollarsPesos">
            Mostrar análisis de dólares para pesos
          </Checkbox>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { Checkbox } from 'element-ui';
import { required, numeric } from 'vuelidate/lib/validators';
import { scrollToTop } from '@/utils/general';

export default {
  components: {
    Checkbox
  },
  props: {
    fillData: Boolean,
    exists: Boolean,
    capital: String
  },

  data() {
    return {
      params: {
        tnaPesos: null,
        tnaDollars: null,
        cropExpDate: null,
        dollarExpDate: null,
        iva: null,
        interest: null,
        includeProfitTax: false,
        includeIvaTax: false,
        includeDollarsPesos: false
      },
      errorMessage: ''
    };
  },

  validations: {
    params: {
      tnaPesos: {
        required,
        numeric
      },
      tnaDollars: {
        required,
        numeric
      },
      cropExpDate: {
        required,
        esFecha30DiasMayor(value) {
          let hoy = new Date();
          let fecha30DiasMayor = new Date(hoy);
          fecha30DiasMayor.setDate(hoy.getDate() + 30);

          return new Date(value) > fecha30DiasMayor;
        }
      },
      dollarExpDate: {
        required,
        esFecha30DiasMayor(value) {
          let hoy = new Date();
          let fecha30DiasMayor = new Date(hoy);
          fecha30DiasMayor.setDate(hoy.getDate() + 30);

          return new Date(value) > fecha30DiasMayor;
        }
      },
      iva: {
        required,
        numeric
      },
      interest: {
        required,
        numeric
      }
    }
  },

  watch: {
    capital: 'calculateInterest',
    'params.cropExpDate': ['calculateInterest', 'calculateIva'],
    'params.tnaPesos': ['calculateInterest', 'calculateIva']
  },

  methods: {
    calculateInterest() {
      if (this.params.cropExpDate && this.params.tnaPesos && this.capital) {
        const today = new Date();
        const expDate = new Date(this.params.cropExpDate);

        const diffInDays = Math.ceil((expDate - today) / (1000 * 60 * 60 * 24));
        const dailyTem = ((this.params.tnaPesos / 100) * 30) / 365;
        this.params.interest = Math.round(diffInDays * this.capital * (dailyTem / 30));
      }
    },
    calculateIva() {
      if (this.params.interest) {
        this.params.iva = Math.round(+this.params.interest * 0.105);
      }
    },
    esFecha30DiasMayor(fecha) {
      let hoy = new Date();
      let fecha30DiasMayor = new Date(hoy);
      fecha30DiasMayor.setDate(hoy.getDate() + 30);

      return fecha > fecha30DiasMayor;
    },
    submitForm() {
      if (this.validate()) {
        this.$emit('addData', this.params);
      } else {
        scrollToTop('.text-invalid');
      }
    },
    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    }
  }
};
</script>
<style scoped>
.input-container {
  display: inline-block;
  max-width: 10rem;
}
</style>
