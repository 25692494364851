<template>
  <div class="col-lg-12">
    <card class="risk-data">
      <div class="edit-button-container" v-if="hasAcceptedQuotation == false">
        <p-button
          cy-id="edit-quotation"
          type="warning"
          class="btn-xs"
          :outline="true"
          @click.native.prevent="showEditForm = !showEditForm"
        >
          <i class="fa fa-pencil" aria-hidden="true"></i>
        </p-button>
      </div>
      <div class="risk-data-title">
        <div class="p-3" v-if="risk.description">
          <p class="h6">Nombre</p>
          <p class="h4">{{ risk.description }}</p>
        </div>
      </div>
      <div class="quotations-header d-flex p-3 justify-content-around text-center">
        <div class="flex-grow-1 text-left quotation-zone">
          <p class="h6">Zona</p>
          <p class="h4">{{ risk.zone.description }}, {{ risk.zone.province.name }}</p>
        </div>
        <div class="flex-grow-1 d-inline-block">
          <p class="h6">Cultivo</p>
          <p class="h4">{{ risk.crop.description }}</p>
        </div>
        <div class="flex-grow-1 d-inline-block">
          <p class="h6">Cantidad HA</p>
          <p class="h4">{{ risk.numberOfHectares }}</p>
        </div>
        <div class="flex-grow-1 d-inline-block">
          <p class="h6">Valor por HA</p>
          <p class="h4">
            {{ risk.hectarePrice }}
            {{ risk.hectarePriceCurrency.id | unidadValorHA }}
          </p>
        </div>
        <div class="flex-grow-1 d-inline-block" v-if="!isSimpleNegotiation">
          <p class="h6">Descuento</p>
          <p class="h4">{{ risk.discount }}%</p>
        </div>
      </div>

      <div>
        <form-risk
          v-if="showEditForm"
          :risk="risk"
          :isSimpleNegotiation="isSimpleNegotiation"
          @submitForm="updateRiskData"
        ></form-risk>

        <tabs :value="initialActiveTab" :centered="true">
          <tab-pane v-if="hasAcceptedQuotation" title="accepted">
            <span slot="title">
              <i class="fa fa-check-circle-o" aria-hidden="true"></i>
              Solicitud
            </span>

            <div>
              <!-- accepted quotation -->
              <risk-accepted-quotation
                :quotation="acceptedQuotation"
                :valueUnit="risk.hectarePriceCurrency.id"
                @getPdf="getPdf"
                @getAdvancePdf="advance"
              ></risk-accepted-quotation>
            </div>
          </tab-pane>
          <tab-pane v-if="!isSimpleNegotiation" title="quotations" id="quotation-list">
            <span slot="title">
              <i class="fa fa-list" aria-hidden="true"></i>
              Lista de cotizaciones
            </span>

            <TablaCotizaciones
              cy-id="quotation-table"
              :rateOption="rateOption"
              :paymentMethod="negotiation.paymentMethodId"
              :unidadValor="risk.hectarePriceCurrency.id"
              :quotations="risk.quotations"
              :seeActions="!hasAcceptedQuotation"
              :estado="negotiationStatus"
              @aceptar="accept"
              @cancelar="cancel"
              @recotizar="recalculate"
              :negotiation="negotiation"
            ></TablaCotizaciones>
          </tab-pane>
          <tab-pane v-if="hasAcceptedQuotation" title="changes">
            <span slot="title">
              <i class="fa fa-calendar" aria-hidden="true"></i>
              Historial de cambios
            </span>

            <div class="row">
              <!-- status history -->
              <div class="col-md">
                <risk-quotation-status-history :quotation="acceptedQuotation"></risk-quotation-status-history>
              </div>
              <div class="col-md">
                <risk-comment :risk="risk" @saveObservations="saveRiskObservations"></risk-comment>
              </div>
            </div>
          </tab-pane>
        </tabs>
      </div>
    </card>
  </div>
</template>

<script>
import FormRisk from '@/components/Forms/FormRisk';
import TablaCotizaciones from '@/components/TablaCotizaciones';

import RiskAcceptedQuotation from './RiskAcceptedQuotation';
import RiskQuotationStatusHistory from './RiskQuotationStatusHistory';
import RiskComment from './RiskComment';

import Tabs from '@/components/Tabs/Tabs';
import TabPane from '@/components/Tabs/Tab';

export default {
  components: {
    FormRisk,
    RiskAcceptedQuotation,
    RiskQuotationStatusHistory,
    RiskComment,
    TablaCotizaciones,
    Tabs,
    TabPane
  },
  props: {
    negotiation: Object,
    risk: Object,
    rateOption: Number,
    negotiationStatus: String,
    isSimpleNegotiation: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showEditForm: false,
      showQuotationTable: false
    };
  },
  mounted() {
    if (!this.hasAcceptedQuotation) {
      this.showQuotationTable = true;
    }
  },
  computed: {
    initialActiveTab() {
      if (!this.hasAcceptedQuotation) {
        return 'quotations';
      } else {
        return 'accepted';
      }
    },
    hasAcceptedQuotation() {
      return this.$props.risk.quotations.some(q => q.quotationStatuses.some(qs => qs.description === 'accepted'));
    },
    hasAdvancedQuotation() {
      return this.$props.risk.quotations.some(q => q.quotationStatuses.some(qs => qs.description === 'advanced'));
    },
    acceptedQuotation() {
      return this.$props.risk.quotations.find(q => q.quotationStatuses.some(qs => qs.description === 'accepted'));
    }
  },
  methods: {
    updateRiskData(riskData) {
      this.showEditForm = false;
      this.$emit('updateRisk', riskData);
    },
    saveRiskObservations(observations) {
      this.$emit('saveRiskObservations', {
        id: this.risk.id,
        risk: this.risk,
        observations
      });
    },
    accept(quotation) {
      this.$emit('accept', quotation);
    },
    cancel(quotation) {
      this.$emit('cancel', quotation);
    },
    recalculate(quotation) {
      this.$emit('recalculate', quotation);
    },
    advance(quotation) {
      if (this.hasAdvancedQuotation) {
        this.$emit('advance', this.acceptedQuotation);
      } else {
        this.$swal({
          title: 'Atención!',
          text: 'Para descargar el anticipo, primero debe enviarse el anticipo con los planos por email.',
          type: 'warning'
        });
      }
    },
    getPdf(quotation) {
      if (this.hasAcceptedQuotation) {
        this.$emit('getPdf', this.acceptedQuotation);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 400px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.btn i.fa {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 10px;
}

.nav-link i.fa {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 10px;
}

.risk-data {
  .edit-button-container {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .h4 {
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .quotations-header {
    display: block !important;
    width: 100%;
  }
  .quotations-header .quotation-zone {
    width: 100%;
    text-align: center !important;
  }
  .quotations-header > div {
    width: 45%;
    display: inline-block;
    margin-bottom: 20px;
  }
}
</style>
