<template>
  <div class="row">
    <div class="col-lg-12">
      <card>
        <div slot="raw-content">
          <form-wizard
            title="Nueva cotización"
            subtitle="Completa el asistente para poder generar la cotización al cliente."
            back-button-text="Atras"
            next-button-text="Siguiente"
            finish-button-text="Finalizar"
            color="#41B883"
            ref="wizard"
            error-color="rgb(221, 51, 51)"
            shape="tab"
            @on-change="changeStep"
          >
            <tab-content title="Cliente" icon="ti-user" :before-change="validateClientData">
              <div class="row">
                <div class="col-xl" />
                <div class="col-xl-8 col-md-12">
                  <producer-form v-if="canSelectProducer" :producer="producer" @onProducerChange="setProducer" />
                  <client-form
                    :client-id="negotiation.clientId"
                    :producer-id="negotiation.producerId"
                    @change="setClient"
                    ref="clientFormRef"
                  />
                  <risk-form :currency="currency" :disabled="false" @cambianDatos="setRisk" ref="riskFormRef" />
                  <div v-if="isDoubleCropAvailable">
                    <p class="h6">Doble cultivo</p>
                    <p-switch v-model="isDoubleCrop" type="success" />
                    <risk-form
                      v-if="isDoubleCrop"
                      :risk="negotiation.risk.doubleCrop"
                      :hectares="negotiation.risk.numberOfHectares"
                      :zone="negotiation.risk.zone"
                      :currency="negotiation.risk.hectarePriceCurrencyId"
                      :isDoubleCrop="true"
                      @cambianDatos="setRiskDoubleCrop"
                      ref="riskFormDoubleCropRef"
                    />
                  </div>
                </div>
                <div class="col-xl" />
              </div>
            </tab-content>

            <tab-content title="Coberturas" icon="ti-view-list-alt" :before-change="validateCoverages">
              <div class="row">
                <div class="col-xl" />
                <div class="col-xl-8 col-md-12">
                  <coverages-form
                    :crop="negotiation.risk.crop?.description"
                    :harvest="mainHarvest"
                    :showDoubleCrop="isDoubleCrop"
                    :showSplitCoverage="canHaveSplitCoverage"
                    :userFavorites="producerFavoriteCoverages"
                    :quotationUserId="negotiation.producerId"
                    @changeCoverages="setCoverages"
                    @cambioFiltroFD="setFiltroFD"
                    @coveragesToSplitChange="coverageToSplitChange"
                  />
                  <split-quote-form v-if="splitCoverages.length > 0" :coverages="splitCoverages" />
                </div>
                <div class="col-xl" />
              </div>
            </tab-content>

            <tab-content title="Configuraciones" icon="ti-settings" :before-change="validateForm">
              <div class="row">
                <div class="col-xl" />
                <div class="col-xl-8 col-md-12">
                  <settings-form
                    :currency="currentCurrency"
                    :configuraciones="negotiation.configurations"
                    @cambioConfiguraciones="setConfiguraciones"
                  />
                  <discount-form v-if="currentStep === 2" :risks="[negotiation.risk]" />
                </div>
                <div class="col-xl" />
              </div>
            </tab-content>

            <tab-content title="Terminar" icon="ti-check">
              <div class="row">
                <div class="col-xl" />
                <div class="col-xl-8 col-md-12">
                  <preview v-if="isPreviewAvailable" :negotiation="negotiationPreview" />
                </div>
                <div class="col-xl" />
              </div>
            </tab-content>

            <template slot="footer" slot-scope="props">
              <div class="row">
                <div class="col-xl" />
                <div class="col-xl-8 col-md-12">
                  <div class="wizard-footer-left">
                    <wizard-button
                      v-if="props.activeTabIndex > 0"
                      @click.native="props.prevTab()"
                      :style="estiloBotonVolver"
                    >
                      <i class="ti-angle-left" />
                      Volver
                    </wizard-button>
                  </div>
                  <div class="wizard-footer-right">
                    <wizard-button
                      v-if="!props.isLastStep"
                      cy-id="next"
                      @click.native="props.nextTab()"
                      class="wizard-footer-right"
                      :style="props.fillButtonStyle"
                    >
                      Siguiente
                      <i class="ti-angle-right" />
                    </wizard-button>

                    <wizard-button
                      v-else
                      cy-id="final"
                      @click.native="saveChanges"
                      class="wizard-footer-right finish-button"
                      :style="props.fillButtonStyle"
                    >
                      {{ props.isLastStep ? 'Finalizar' : 'Siguiente' }}
                    </wizard-button>
                  </div>
                </div>
                <div class="col-xl" />
              </div>
            </template>
          </form-wizard>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import PSwitch from '@/components/Switch.vue';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import { isAdmin, isSuperUser } from '@/plugins/roles.js';
import constants from '@/plugins/constants.js';
import RiskForm from '@/components/Negotiation/Forms/RiskForm';
import ProducerForm from '@/components/Negotiation/Forms/ProducerForm';
import ClientForm from '@/components/Negotiation/Forms/ClientForm';
import { generateEmptyRisk } from '@/utils/risk';
import { scrollToTop } from '@/utils/general';
import DiscountForm from '@/components/Negotiation/Forms/DiscountForm';
import CoveragesForm from '@/components/Negotiation/Forms/CoveragesForm.vue';
import SettingsForm from '@/components/Negotiation/Forms/SettingsForm.vue';
import Preview from '@/components/Negotiation/Forms/Preview.vue';
import SplitQuoteForm from '@/components/Negotiation/Forms/SplitQuoteForm.vue';

export default {
  name: 'Nuevo',
  components: {
    FormWizard,
    WizardButton,
    TabContent,
    PSwitch,
    ProducerForm,
    CoveragesForm,
    SplitQuoteForm,
    SettingsForm,
    DiscountForm,
    Preview,
    RiskForm,
    ClientForm
  },
  data() {
    return {
      currentStep: 0,
      isDoubleCrop: false,
      isPreviewAvailable: false,
      currency: constants.QUINTAL,
      producer: this.$auth.user(),
      negotiation: {
        type: constants.SIMPLE,
        state: constants.INITIATED,
        clientId: 0,
        producerId: this.$auth.user().id,
        risks: [],
        risk: {
          ...generateEmptyRisk(0),
          doubleCrop: null,
          isDoubleCrop: null,
          discount: null
        },
        coverages: [],
        fdFilter: '',
        configurations: {
          paymentMethod: 0,
          rateOption: 0,
          correctionFactor: 0,
          observations: this.configurations ? this.configurations.observations : ''
        },
        createdInWeb: ''
      },
      double: {
        id: 0,
        discount: null
      },
      splitCoverages: [],
      negotiationPreview: {},
      producerFavoriteCoverages: [],
      estiloBotonVolver: {
        backgroundColor: '#fafafa',
        borderColor: '#e0e0e0',
        color: '#999'
      },
      client: null
    };
  },
  computed: {
    currentCurrency() {
      return this.negotiation.risk.hectarePriceCurrencyId;
    },
    canHavePaymentInKind() {
      return this.negotiation.risk.hectarePriceCurrencyId === constants.QUINTAL;
    },
    canHaveSplitCoverage() {
      return this.$auth.user().allow_split_coverages === 1;
    },
    isDoubleCropAvailable() {
      return this.negotiation.risk.crop?.allowDoubleCrop === 1;
    },
    mainHarvest() {
      return this.negotiation.risk.crop?.harvest ?? '';
    },
    canSelectProducer() {
      return isAdmin(this.$auth.user()) || isSuperUser(this.$auth.user());
    }
  },
  mounted() {
    this.fetchProducerCoverages(this.negotiation.producerId);
  },
  methods: {
    changeStep(prevIndex, nextIndex) {
      this.currentStep = nextIndex;
    },
    saveChanges() {
      this.negotiationPreview.isConfirmed = 1;
      this.negotiationPreview.state = constants.INITIATED;
      this.negotiationPreview.type = constants.SIMPLE;
      this.$api.negotiations.update(this.negotiationPreview.id, this.negotiationPreview).then(response => {
        this.$swal({
          title: 'Listo!',
          text: 'Cotización creada correctamente',
          type: 'success'
        });
        this.$router.push({ name: 'cotizaciones' });
      });
    },
    validateClientData() {
      let isValid = true;
      let errors = [];

      if (!this.$refs.clientFormRef.validate()) isValid = false;

      if (this.isDoubleCrop && !this.$refs.riskFormDoubleCropRef.validate()) isValid = false;

      if (!this.$refs.riskFormRef.validate()) isValid = false;

      if (!isValid) {
        scrollToTop('.text-invalid');
        this.handleValidationErrors(errors);
      }

      return isValid;
    },
    validateCoverages() {
      return new Promise((resolve, reject) => {
        let isValid = true;
        let errors = [];
        if (this.negotiation.modules.length === 0 && !this.isDoubleCrop) {
          errors.push('Debe seleccionar al menos una cobertura.');
          isValid = false;
        }
        if (this.canHaveSplitCoverage && this.splitCoverages.length > 0) {
          let sumValues = 0;
          this.splitCoverages.forEach(sc => {
            sumValues += parseInt(sc.splitValue);
          });
          if (sumValues !== parseInt(this.negotiation.risk.hectarePrice)) {
            // values should match
            isValid = false;
            errors.push(
              'Los valores del riesgo y de la ponderación deben coincidir. Riesgo: ' +
                this.negotiation.risk.hectarePrice +
                ' / Ponderación: ' +
                sumValues
            );
          }
        }
        if (isValid) {
          resolve(true);
        } else {
          this.handleValidationErrors(errors);
          reject(new Error('Error de validación'));
        }
      });
    },
    validateForm() {
      return new Promise((resolve, reject) => {
        // Realizar validaciones, antes de enviar los datos.
        let isValid = true;

        let errors = [];

        if (this.negotiation.configurations.paymentMethod === 0) {
          isValid = false;
          errors.push('Debe seleccionar una forma de pago.');
        }

        if (
          this.negotiation.configurations.rateOption === 0 ||
          this.negotiation.configurations.rateOption === '' ||
          this.negotiation.configurations.rateOption === undefined
        ) {
          isValid = false;
          errors.push('Debe seleccionar una opción de tasa.');
        }

        if (this.negotiation.risk.discount === null || this.negotiation.risk.discount === '') {
          isValid = false;
          errors.push('El descuento debe ser un valor mayor o igual a cero.');
        }

        if (isValid) {
          if (this.client) {
            this.$api.clients.create(this.client).then(response => {
              this.negotiation.clientId = response.id;
              this.createNegotiation(resolve);
            });
          } else {
            this.createNegotiation(resolve);
          }
        } else {
          this.handleValidationErrors(errors);
          reject(new Error('Error de validación'));
        }
      });
    },
    createNegotiation(resolve) {
      if (this.negotiation.configurations.paymentMethod !== 3) {
        this.negotiation.configurations.correctionFactor = 0;
      }
      this.$api.simpleNegotiations.create(this.negotiation).then(response => {
        this.negotiationId = response.data.id;
        this.saveRisk(resolve);
      });
    },
    saveRisk(resolve) {
      this.negotiation.risk.negotiationId = this.negotiationId;
      if (this.isDoubleCrop) {
        this.negotiation.risk.isDoubleCrop = this.isDoubleCrop;
        this.negotiation.risk.doubleCropId = this.negotiation.risk.doubleCrop.crop.id;
        this.negotiation.risk.doubleCrop.zone = this.negotiation.risk.zone;
      }
      if (this.splitCoverages) this.negotiation.risk.splitCoverages = this.splitCoverages;
      this.$api.risks.create(this.negotiation.risk).then(response => {
        if (this.isDoubleCrop) {
          this.negotiation.risk.id = response[0].id;
          this.double.id = response[1].id;
          this.double.discount = this.negotiation.risk.discount;
          this.negotiation.risks.push(this.double);
        } else {
          this.negotiation.risk.id = response.id;
        }
        this.finishNegotiation(resolve);
      });
    },
    finishNegotiation(resolve) {
      this.negotiation.risks = [];
      this.negotiation.risks.push(this.negotiation.risk);
      if (this.isDoubleCrop) {
        this.negotiation.risks.push(this.double);
      }
      if (this.splitCoverages) this.negotiation.splitCoverages = this.splitCoverages;
      this.$api.finishNegotiation
        .finishNegotiation(this.negotiationId, this.negotiation)
        .then(response => {
          this.isPreviewAvailable = true;
          this.negotiationPreview = response.data;
          resolve(true);
        })
        .catch(error => {
          this.$swal({
            title: 'Error!',
            text: 'Ocurrió un error mientras estaba generando la vista previa.',
            type: 'error',
            footer: 'ERROR ' + error.response.data.code + ': ' + error.response.data.message
          });
          reject(new Error('Error del servidor'));
        });
    },
    handleValidationErrors(errors) {
      if (errors.length > 0) {
        let errorText = '<p>Ocurrió un error de validación.</p>';
        let errorList = '<ul style="list-style-type:none">';
        errors.forEach(e => {
          errorList += '<li>- ' + e + '</li>';
        });
        errorList += '</ul>';
        this.$swal({
          title: 'Error!',
          html: errorText + errorList,
          type: 'error'
        });
      }
    },
    setProducer(value) {
      this.negotiation.producerId = value.id;
      this.currency = value.hectarePriceCurrency ? value.hectarePriceCurrency : this.currency;
      this.negotiation.configurations.paymentMethod = value.paymentMethod ? value.paymentMethod : this.paymentMethod;
      this.negotiation.configurations.rateOption = value.rateOption ? value.rateOption : this.rateOption;
    },
    setClient(value) {
      if (typeof value === 'number') {
        this.negotiation.clientId = value;
      } else {
        this.client = value;
      }
    },
    setCoverages(value) {
      this.negotiation.modules = value;
    },
    setFiltroFD(value) {
      this.negotiation.fdFilter = value;
    },
    setConfiguraciones(value) {
      this.negotiation.configurations = value;
    },
    setRisk(value) {
      this.negotiation.risk = value;
    },
    setRiskDoubleCrop(value) {
      this.negotiation.risk.doubleCrop = value;
    },
    coverageToSplitChange(coveragesToSplit) {
      this.splitCoverages = coveragesToSplit;
    },

    fetchProducerCoverages(producerId) {
      this.$api.users.getCoverages(producerId).then(response => {
        this.producerFavoriteCoverages = response.data.modules;
      });
    }
  },
  watch: {
    'negotiation.risk.crop': {
      handler(newVal, oldVal) {
        if (newVal?.harvest !== constants.THIN_HARVEST.key) {
          this.isDoubleCrop = false;
        }
      },
      deep: true
    },
    'negotiation.producerId': {
      handler(producerId) {
        this.fetchProducerCoverages(producerId);
      }
    },
    isDoubleCrop: function (newValue, oldValue) {
      if (newValue === true) {
        this.negotiation.type = 'double-crop';
      } else {
        this.negotiation.type = 'simple';
      }
    }
  }
};
</script>

<style></style>
