<template>
  <div>
    <div class="alert alert-success alert-with-icon" v-if="showDoubleCrop">
      <span data-notify="icon" class="ti-check-box"></span>
      <span data-notify="message">
        Estas cotizando con
        <b>doble cultivo</b>
        . Las coberturas de doble cultivo se seleccionan automáticamente.
        <b>Solo debe elegir las coberturas extra a cotizar para la cosecha fina</b>
        .
      </span>
    </div>
    <div class="row">
      <div class="col-md-6 col-sm-12 d-flex">
        <button class="btn btn-primary btn-lg button-select-all" @click="toggleSeleccionarTodas">
          {{ stringButton }}
        </button>
      </div>
      <div class="col-sm-6">
        <div>
          <fieldset>
            <h4 class="m-0">Mostrar:</h4>
            <div class="d-inline-block m-2">
              <input type="radio" id="franquicia" name="franquicia_deducible" value="franquicia" v-model="fdFilter" />
              <label for="franquicia">Franquicia</label>
            </div>
            <div class="d-inline-block m-2">
              <input type="radio" id="deducible" name="franquicia_deducible" value="deducible" v-model="fdFilter" />
              <label for="deducible">Deducible</label>
            </div>
            <div cy-id="both" class="d-inline-block m-2">
              <input type="radio" id="ambos" name="franquicia_deducible" value="ambos" v-model="fdFilter" />
              <label for="ambos">Ambos</label>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <h4>Coberturas Básicas</h4>
        <coverage-item
          cy-id="coverage-item"
          v-for="coverage in coveragesBasicas"
          :key="coverage.id"
          :coverage="coverage"
          :isSplit="showSplitCoverage"
          :quotationUserId="quotationUserId"
        />
      </div>
      <div class="col-sm-6" v-if="coveragesAdicionales.length > 0">
        <h4>Coberturas Adicionales</h4>
        <coverage-item
          cy-id="coverage-additional-item"
          v-for="coverage in coveragesAdicionales"
          :key="coverage.id"
          :coverage="coverage"
          :quotationUserId="quotationUserId"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6" v-if="coveragesPacks.length > 0">
        <h4>Coberturas Packs</h4>
        <coverage-item
          cy-id="coverage-pack-item"
          v-for="coverage in coveragesPacks"
          :key="coverage.id"
          :coverage="coverage"
          :quotationUserId="quotationUserId"
        />
      </div>
    </div>
  </div>
</template>
<script>
import CoverageItem from './CoveragesForm/CoverageItem.vue';

export default {
  name: 'CoveragesForm',
  components: {
    CoverageItem
  },
  props: {
    harvest: {
      type: String,
      default: ''
    },
    showDoubleCrop: {
      type: Boolean,
      default: false
    },
    showSplitCoverage: {
      type: Boolean,
      default: false
    },
    userFavorites: {
      type: Array,
      default: () => []
    },
    quotationUserId: {
      type: Number,
      default: 0
    },
    crop: {
      type: String,
      default: ''
    },
    negotiationGroup: {
      type: Boolean,
      default: false
    },
    risks: {
      type: Array,
      default: () => []
    },
    modules: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      coverages: [],
      fdFilter: 'ambos',
      buttonState: false
    };
  },
  mounted() {
    this.fetchCoverages();
  },
  methods: {
    fetchCoverages() {
      this.$api.modules.getAllModules().then(response => {
        this.coverages = response.data.modules.map(cov => {
          cov.isSplit = false;
          cov.isSelected = this.userFavorites.includes(cov.id);
          cov.isSelected = this.modules ? this.modules.includes(cov.id):false
          return cov;
        });
      });
    },
    toggleSeleccionarTodas() {
      !this.buttonState ? this.coverageChangeState(true) : this.coverageChangeState(false);
    },
    coverageChangeState(prop) {
      this.coveragesBasicas.forEach(cov => {
        cov.isSelected = prop;
      });
      this.coveragesPacks.forEach(cov => {
        cov.isSelected = prop;
      });
      this.coveragesAdicionales.forEach(cov => {
        cov.isSelected = prop;
      });
      this.buttonState = prop;
    }
  },

  computed: {
    coveragesSelected() {
      return this.coverages.filter(cov => cov.isSelected).map(cov => cov.id);
    },
    stringButton() {
      return !this.buttonState ? 'Seleccionar Todas' : 'Deseleccionar Todas';
    },
    coveragesBasicas() {
      return this.coverages.filter(
        c => c.coverageType === 'basic' && (c.cropDescription === this.crop || this.risks.includes(c.cropDescription))
      );
    },

    coveragesAdicionales() {
      return this.coverages.filter(
        c => c.coverageType === 'addon' && (c.cropDescription === this.crop || this.risks.includes(c.cropDescription))
      );
    },

    coveragesPacks() {
      return this.coverages.filter(
        c => c.coverageType === 'pack' && (c.cropDescription === this.crop || this.risks.includes(c.cropDescription))
      );
    },

    coveragesDobleCultivo() {
      return this.coverages.filter(
        c =>
          c.coverageType === 'double-crop' &&
          (c.cropDescription === this.crop || this.negotiationGroup) &&
          this.showDoubleCrop
      );
    },

    splitCoverages() {
      return this.coverages.filter(cov => cov.isSelected && cov.isSplit);
    }
  },
  watch: {
    harvest(newValue, oldValue) {
      this.coverages.forEach(cov => {
        if (cov.isSelected && cov.harvest !== newValue) {
          cov.isSelected = false;
        }
      });
    },
    coveragesSelected(newValue, oldValue) {
      this.$emit('changeCoverages', newValue);
    },
    fdFilter(newValue, oldValue) {
      this.$emit('cambioFiltroFD', newValue);
    },
    modules(newValue, oldValue) {
      this.$emit('coverageSelected', newValue);
    },
    splitCoverages(newValue, oldValue) {
      this.$emit('coveragesToSplitChange', newValue);
    },
    userFavorites(favs) {
      this.coverages.forEach(cov => {
        cov.isSelected = favs.includes(cov.id);
      });
    }
  }
};
</script>
<style scoped>
@media (max-width: 600px) {
  ::v-deep .button-select-all {
    flex-grow: 1;
    margin: 5px 0 5px 0;
  }
}
</style>
