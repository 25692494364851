<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <card>
          <div class="card-header">
            <div class="pull-left">
              <h4 class="card-title">
                Detalle de la cotización #
                <strong>{{ this.negotiation.number }}</strong>
              </h4>
              <p class="card-category">
                Negociación iniciada el:
                {{ this.negotiation.createdAt | formatoFecha }}
                {{ createdFormat }}
              </p>
            </div>
            <div class="card-header-button-container">
              <button class="btn btn-warning" v-if="canUpdated" @click="editNegotiation">Editar</button>
              <button class="btn btn-danger" v-if="canReject" @click="rejectNegotiation">Cancelar</button>
              <button
                cy-id="delete-quotation"
                class="btn btn-outline-danger"
                v-if="
                  this.$auth.check('ROLE_ADMIN') || this.$auth.check('ROLE_SUPERVISOR') || this.$auth.check('ROLE_SUDO')
                "
                @click="deleteNegotiation"
              >
                Eliminar
              </button>
            </div>
          </div>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <card class>
          <div>
            <h4 class="card-title">Reportes</h4>
            <div class="row mt-3">
              <div class="col-12 col-md-4">
                <h6 class="mb-2">Coberturas</h6>
                <a :href="coveragesPerModuleURL" target="_blank" class="btn btn-warning d-block mb-2">
                  Coberturas por modulo
                </a>
                <a :href="coveragesDescriptionURL" target="_blank" class="btn btn-warning d-block mb-2">
                  Descriptivo Coberturas
                </a>
              </div>
              <div class="col-12 col-md-4">
                <h6 class="mb-2">PDF Cotización</h6>
                <button class="btn btn-info btn-block mb-2" @click="fetchPDF(1)">Descargar PDF</button>
                <button class="btn btn-info btn-block mb-2" @click="fetchPDF(0)">Descargar PDF sin costos</button>
              </div>
              <div class="col-12 col-md-4">
                <h6 class="mb-2">Anticipos</h6>
                <button
                  cy-id="aceptar-anticipo"
                  class="btn btn-primary btn-block mb-2"
                  v-if="canBeAnticipated"
                  @click="fetchAdvancePDF"
                >
                  Descargar Anticipo
                </button>
                <button
                  cy-id="advance-email"
                  class="btn btn-primary btn-block mb-2"
                  v-if="canBeAnticipated"
                  @click="toggleAddMaps"
                >
                  Anticipo por email
                </button>
                <div class="alert alert-secondary" role="alert" v-else>
                  Para enviar un anticipo, primero debes aceptar una cotización
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>

    <div class="row" v-if="showSendAdvance">
      <div class="col-xl-12">
        <card class>
          <div>
            <h4 class="card-title">Enviar anticipo por email</h4>
            <div>
              <div class="form-group">
                <label class="control-label">Clickee el boton para adjuntar los planos</label>
                <input type="file" id="planos" @change="changeFile" multiple class="form-control" accept="image/*" />
              </div>
              <div class="planos-preview-container" v-if="mapsPreviewUrls.length > 0">
                <p class="h6">Vista previa de los planos</p>
                <div class="planos-preview-item" v-for="(pp, index) in mapsPreviewUrls" :key="index">
                  <img :src="pp" />
                </div>
              </div>
              <button cy-id="send-advance" class="btn btn-success" @click="sendAdvance">Enviar anticipo</button>
              <button class="btn btn-outline-default ml-2" @click="toggleAddMaps">Cancelar</button>
            </div>
          </div>
        </card>
      </div>
    </div>

    <div class="row row-eq-height" v-if="isDataLoaded">
      <div class="col-xl-4 col-md-6">
        <card class>
          <div class="d-flex align-items-center">
            <h5>Productor</h5>
            <span v-if="negotiation.user.deletedAt" class="badge badge-danger ml-auto">Productor Eliminado</span>
            <div class="ml-auto" v-else>
              <router-link
                :to="{
                  name: 'ver usuario',
                  params: { id: this.negotiation.user.id }
                }"
                class="btn btn-sm btn-info"
              >
                <i class="ti-eye" />
              </router-link>
            </div>
          </div>
          <div>
            <p class="h6">Nombre</p>
            <p>{{ this.negotiation.user.name }}</p>
          </div>
          <div>
            <p class="h6">CUIT</p>
            <p>{{ this.negotiation.user.cuit }}</p>
          </div>
          <div>
            <p class="h6">Oficina</p>
            <p>{{ this.negotiation.user.office }}</p>
          </div>
          <div>
            <p class="h6">Email</p>
            <p>{{ this.negotiation.user.email }}</p>
          </div>
        </card>
      </div>

      <div class="col-xl-4 col-md-6">
        <card class>
          <div class="d-flex align-items-center">
            <h5>Cliente</h5>
            <span v-if="negotiation.client.deletedAt" class="badge badge-danger ml-auto">Cliente Eliminado</span>
            <div class="ml-auto" v-else>
              <router-link
                :to="{
                  name: 'ver cliente',
                  params: { id: this.negotiation.client.id }
                }"
                class="btn btn-sm btn-info"
              >
                <i class="ti-eye" />
              </router-link>
            </div>
          </div>

          <div>
            <p class="h6">Nombre</p>
            <p>{{ negotiation.client.name ?? '' }} {{ negotiation.client.lastname }}</p>
          </div>
          <div>
            <p class="h6">CUIT</p>
            <p>{{ this.negotiation.client.cuit }}</p>
          </div>
          <div>
            <p class="h6">Teléfono</p>
            <p>{{ this.negotiation.client.cell }}</p>
          </div>
          <div>
            <p class="h6">Ubicación</p>
            <p>
              {{ this.negotiation.client.location }},
              {{ this.negotiation.client.province }}
            </p>
          </div>
        </card>
      </div>

      <div class="col-xl-4 col-md-6">
        <card class>
          <h5>Configuraciones</h5>
          <div>
            <p class="h6">Forma de pago</p>
            <p>{{ this.negotiation.paymentMethodId | formaPago }}</p>
          </div>
          <div>
            <p class="h6">Opción de tasa</p>
            <p>{{ this.negotiation.rateOptionId | opcionTasa }}</p>
          </div>
          <div v-if="[paymentMethods.KIND, paymentMethods.FINANCED_KIND].includes(negotiation.paymentMethodId)">
            <p class="h6">Factor de Corrección</p>
            <p>{{ this.negotiation.correctionFactor }}%</p>
          </div>
        </card>
      </div>
    </div>

    <!-- Lista de Cotizaciones -->
    <div class="row" v-if="isDataLoaded">
      <div class="col-lg-12">
        <h3>Cotizaciones generadas</h3>
      </div>
      <risk-data
        v-for="risk in this.negotiation.risks"
        :key="risk.id"
        :risk="risk"
        :negotiation="negotiation"
        :rateOption="negotiation.rateOptionId"
        :negotiationStatus="negotiation.state"
        @updateRisk="updateRiskData"
        @accept="acceptQuotation"
        @cancel="rejectQuotation"
        @recalculate="updateQuotation"
        @advance="getAdvancePdf"
        @getPdf="getAcceptedPdf"
        @saveRiskObservations="saveRiskObservations"
      />
    </div>
  </div>
</template>

<script>
import api from '@/api/index.js';
import constants from '@/plugins/constants.js';
import paymentMethods from '@/plugins/paymentMethods.js';
import RiskData from '@/components/Risk/RiskData';
import { downloadBlob } from '@/plugins/files.js';

const tableColumns = ['Cobertura', 'Deducible/Franquicia', 'Premio', 'Costo', 'Costo Total por Hectarea', 'Estado'];

export default {
  components: {
    RiskData
  },
  data() {
    return {
      showSendAdvance: false,
      maps: [],
      negotiation: {},
      isDataLoaded: false,
      columnasCotizaciones: [],
      constants: constants,
      paymentMethods: paymentMethods
    };
  },

  created() {
    this.fetchNegotiationData();
    this.columnasCotizaciones = tableColumns;
  },
  computed: {
    negotiationId() {
      return this.$route.params.id;
    },
    coveragesPerModuleURL() {
      return api.baseURL + '/storage/docs/COBERTURAS_POR_MODULO_24-25.pdf';
    },
    coveragesDescriptionURL() {
      return api.baseURL + '/storage/docs/DESCRIPTIVO.pdf';
    },
    mapsPreviewUrls() {
      let previews = [];
      this.maps.forEach(pl => {
        if (pl.type.includes('image')) {
          let url = URL.createObjectURL(pl);
          previews.push(url);
        }
      });

      return previews;
    },
    canReject() {
      return (
        Boolean(this.negotiation.state) &&
        this.negotiation.state !== constants.ACCEPTED &&
        this.negotiation.state !== constants.REJECTED
      );
    },
    canUpdated() {
      return Boolean(
        !this.negotiation.risks.some(r =>
          r.quotations.some(q =>
            q.quotationStatuses.some(
              qs => qs.description === 'accepted' || qs.description === 'advanced' || qs.description === 'proposal'
            )
          )
        )
      );
    },
    canBeAnticipated() {
      if (
        this.negotiation.risks &&
        this.negotiation.risks.some(r =>
          r.quotations.some(q => q.quotationStatuses.some(qs => qs.description === 'accepted'))
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    createdFormat() {
      if (this.negotiation.createdInWeb) {
        return 'Utilizando Sitio Web';
      }
      return 'Utilizando App Mobile';
    }
  },
  methods: {
    fetchNegotiationData() {
      this.$api.negotiations
        .getOne(this.negotiationId)
        .then(response => {
          this.negotiation = response.data;
          this.isDataLoaded = true;
        })
        .catch(error => {
          console.error(error);
        });
    },
    saveRiskObservations(data) {
      data.risk.observations = data.observations;
      this.$api.risks.update(data.id, data.risk).then(response => {
        this.$api.negotiations.getOne(this.negotiationId).then(response => {
          this.negotiation = response.data;
          this.$swal({
            title: 'Listo!',
            text: 'Los datos del riesgo fueron actualizados.',
            type: 'success'
          });
        });
      });
    },
    fetchPDF(showCosts) {
      this.$api.negotiations
        .reportPdf(this.negotiationId, showCosts)
        .then(response => {
          const fileName = this.getPdfFileName();
          downloadBlob(new Blob([response]), fileName);
        })
        .catch(error => {
          console.error(error);
        });
    },
    fetchAdvancePDF() {
      this.$api.negotiations
        .advancePdf(this.negotiationId)
        .then(response => {
          const fileName = this.getPdfFileName();
          downloadBlob(new Blob([response]), fileName);
        })
        .catch(error => {
          this.$swal({
            title: 'Error',
            text: 'No hay ninguna cotización anticipada',
            type: 'error'
          });
        });
    },
    toggleAddMaps() {
      this.showSendAdvance = !this.showSendAdvance;
      if (!this.showSendAdvance) this.maps = [];
    },
    changeFile(event) {
      let array = [];
      const input = document.getElementById('planos');

      array.push(...event.target.files);

      array.forEach(map => {
        if (/^image\//.test(map.type)) {
          this.maps.push(map);
        } else {
          this.$swal({
            title: 'Error!',
            text: `El archivo (${map.name}) no es una imagen`,
            type: 'error'
          });
          input.value = '';
        }
      });
    },
    sendAdvance() {
      if (this.maps.length > 0) {
        this.confirmSendAdvance();
      } else {
        this.$swal({
          title: 'Atención! El anticipo se va a enviar sin planos.',
          text: '¿Desea enviarlo de todas formas?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#28a745',
          confirmButtonText: 'Enviar',
          cancelButtonText: 'Cancelar'
        }).then(result => {
          if (result.value) {
            this.confirmSendAdvance();
          }
        });
      }
    },
    confirmSendAdvance() {
      let formData = new FormData();

      const isImage = file => file['type'].includes('image');
      this.maps.forEach((p, index) => {
        if (p.type.includes('image')) {
          formData.append('images[' + index + ']', p, p.name);
        }
      });

      this.$api.negotiations
        .advanceMail(this.negotiationId, formData)
        .then(response => {
          this.$swal({
            title: 'Listo!',
            text: 'El anticipo fue enviado por email de forma exitosa.',
            type: 'success'
          });
          this.fetchNegotiationData();
          this.showSendAdvance = !this.showSendAdvance;
        })
        .catch(error => {
          this.$swal({
            title: 'Error!',
            text: 'Ocurrió un error mientras estaba enviando el email.',
            type: 'error',
            footer: 'ERROR ' + error.response.data.code + ': ' + error.response.data.message
          });
        });
    },
    acceptQuotation(quotation) {
      this.$api.quotations
        .accept(quotation.quotation.id, quotation.comment)
        .then(response => {
          this.fetchNegotiationData();
        })
        .catch(error => {
          console.error(error);
        });
    },
    rejectQuotation(quotation) {
      this.$api.quotations
        .reject(quotation.id)
        .then(response => {
          this.fetchNegotiationData();
        })
        .catch(error => {
          console.error(error);
        });
    },
    updateQuotation(quotation) {
      this.$api.quotations
        .update(quotation.id, {})
        .then(response => {
          this.fetchNegotiationData();
        })
        .catch(error => {
          console.error(error);
        });
    },
    getAdvancePdf(quotation) {
      this.$api.quotations.advancedPdf(quotation.id).then(response => {
        const fileName = `pdf_anticipo_${this.negotiation.number}_${quotation.id}.pdf`;
        downloadBlob(new Blob([response]), fileName);
      });
    },
    editNegotiation() {
      this.$swal({
        title: '¿Estás seguro que desea editar la cotización?',
        text: 'Para editar la cotización debe completar todos los pasos hasta presionar el botón finalizar. De otra forma no se reflejaran los cambios.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Si, editar.'
      }).then(result => {
        if (result.value) {
          this.$router.push({ name: 'editar cotizacion', params: { id: this.negotiation.id } });
        }
      });
    },
    rejectNegotiation() {
      this.$swal({
        title: '¿Estás seguro de cancelar la cotización?',
        text: 'Luego de esto, no podrás deshacer los cambios.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Si, cancelar!'
      }).then(result => {
        if (result.value) {
          this.$api.negotiations
            .reject(this.negotiationId)
            .then(response => {
              this.fetchNegotiationData();
            })
            .catch(error => {
              console.error(error);
            });
        }
      });
    },
    deleteNegotiation() {
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Luego de esto, no podrás deshacer los cambios.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Si, eliminar!'
      }).then(result => {
        if (result.value) {
          this.$api.negotiations
            .destroy(this.negotiationId)
            .then(() => {
              this.$swal({
                title: 'Listo!',
                text: 'La cotización fue eliminada correctamente.',
                type: 'success'
              });
              this.$router.push({ name: 'cotizaciones' });
            })
            .catch(error => {
              console.error(error);
            });
        }
      });
    },
    calculateAdditional(quotation) {
      let total = 0;
      quotation.addons.forEach(item => {
        total += item.amount;
      });
      return total;
    },
    getPdfFileName() {
      let filename = [];
      filename.push(this.negotiation.number);
      if (this.negotiation.client.name) {
        filename.push(this.negotiation.client.name.replace(/ /g, '-'));
      }
      if (this.negotiation.client.lastname) {
        filename.push(this.negotiation.client.lastname.replace(/ /g, '-'));
      }
      if (this.negotiation.type === 'simple') {
        filename.push(this.negotiation.risks[0].crop.description.replace(/ /g, '-'));
        filename.push(this.negotiation.risks[0].numberOfHectares);
      } else if (this.negotiation.type === 'double-crop') {
        filename.push(this.negotiation.risks[0].crop.description.replace(/ /g, '-'));
        filename.push('DOBLE CULTIVO');
        filename.push(this.negotiation.risks[1].crop.description.replace(/ /g, '-'));
        filename.push(this.negotiation.risks[0].numberOfHectares);
      } else {
        let risksLength = this.negotiation.risks.length + ' Riesgos';
        filename.push(risksLength.replace(/ /g, '-'));
      }
      filename = filename.join('_');
      filename += '.pdf';
      return filename;
    },

    updateRiskData(riskFormData) {
      this.$api.risks.update(riskFormData.id, riskFormData).then(response => {
        this.$api.negotiations.getOne(this.negotiationId).then(response => {
          this.negotiation = response.data;
          this.$swal({
            title: 'Listo!',
            text: 'Los datos del riesgo fueron actualizados.',
            type: 'success'
          });
        });
      });
    },

    getAcceptedPdf(quotation) {
      this.$api.quotations.acceptedPdf(quotation.id).then(response => {
        const fileName = `cotizacion_aceptada_${this.negotiation.number}_${quotation.id}.pdf`;
        downloadBlob(new Blob([response]), fileName);
      });
    }
  }
};
</script>

<style lang="scss">
.planos-preview-container {
  margin-bottom: 10px;

  .planos-preview-item {
    display: inline-block;
    margin-top: 10px;
    margin-right: 10px;

    img {
      height: 200px;
    }
  }
}

.quotations-header {
  > div {
    flex-basis: 0;
    p {
      margin: 0;
    }
  }
}

.card-title strong {
  font-weight: bold;
}
</style>
