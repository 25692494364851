<template>
  <div class="row">
    <div class="col-xl-8">
      <user-form :user="user" title="Nuevo productor" @submit="addUser" />
    </div>
  </div>
</template>

<script>
import UserForm from '@/components/Users/Form/UserForm';
export default {
  name: 'NewView',
  components: {
    UserForm
  },
  data() {
    return {
      user: {
        name: '',
        cuit: '',
        address: '',
        location: '',
        province: '',
        office: '',
        fileNumber: '',
        sancorId: '',
        type: '',
        cell: '',
        email: '',
        username: '',
        password: '',
        password_confirmation: '',
        roleId: 4,
        seeVideo: false,
        allow_paymentInKind: false,
        allowDiscountsOnPacks: false,
        allowSplitCoverages: false,
        seePdfDescription: false,
        seeSimulator: false,
        preferredUrl: '',
        seeCoverageUserModule: false,
        sendProposal: false,
        exchangeStoreId: '',
        maxDiscountAllow: 0,
        paymentMethod: null,
        rateOption: null,
        hectarePriceCurrency: null
      }
    };
  },
  methods: {
    addUser() {
      this.$api.users
        .create(this.user)
        .then(response => {
          this.$swal({
            title: 'Listo!',
            text: 'El productor fue registrado.',
            type: 'success'
          });
          this.$router.push({ name: 'usuarios' });
        })
        .catch(err => {
          this.$swal({
            title: 'Error!',
            text: err[0],
            type: 'error'
          });
        });
    }
  }
};
</script>

<style></style>
