import RestResource from '../rest.service';
export default class NotesService extends RestResource {
  map(payload) {
    return {
      payment_method_id: payload.paymentMethodId,
      rate_option_id: payload.rateOptionId,
      hectare_price_currency_id: payload.hectarePriceCurrencyId,
      user_id: payload.userId,
      value: payload.value
    };
  }
  constructor(api) {
    super(api, 'notes', 'api');
  }
}
