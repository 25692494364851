import RestResource from '../rest.service';
import axios from 'axios';

export default class RefinanceService extends RestResource {
  map(payload) {
    return {
      capital: payload.capital,
      negotiation_id: payload.negotiationId,
      crops: payload.crops.map(crop => {
        return {
          crop_id: crop.cropId,
          tna_pesos: crop.tnaPesos,
          tna_dollars: crop.tnaDollars,
          iva: crop.iva,
          interest: crop.interest,
          crop_exp_date: crop.cropExpDate,
          dollar_exp_date: crop.dollarExpDate,
          include_profit_tax: crop.includeProfitTax,
          include_iva_tax: crop.includeIvaTax,
          include_dollars_pesos: crop.includeDollarsPesos
        };
      })
    };
  }

  getRefinance(payload) {
    return this.post(`${axios.defaults.baseURL}/api/refinance`, this.map(payload));
  }

  getPdf(payload) {
    return this.post(`${axios.defaults.baseURL}/api/refinance-pdf`, this.map(payload), {
      headers: {
        Accept: 'application/pdf'
      },
      responseType: 'blob'
    });
  }

  getExcel(payload) {
    return this.post(`${axios.defaults.baseURL}/api/refinance-excel`, this.map(payload), {
      responseType: 'blob'
    });
  }
}
