<template>
  <div>
    <div>
      <div class="filtros-container" v-if="hasSearch">
        <slot name="filters">
          <div class="d-flex">
            <fg-input
              cy-id="search"
              type="text"
              label="Buscar"
              placeholder="Buscar"
              class="search-input"
              v-model="params.search"
            />
          </div>
        </slot>
      </div>
    </div>
    <table class="table" :class="tableClass" v-if="tableData.length > 0">
      <thead>
        <slot name="columns">
          <th v-for="column in columns" :key="column">
            {{ column }}
          </th>
          <th v-if="hasActions">Acciones</th>
        </slot>
      </thead>
      <tbody>
        <tr v-for="(item, rowIndex) in tableData" :key="`et-row-${rowIndex}`">
          <slot :row="item" :index="rowIndex">
            <td v-for="(column, colIndex) in columns" :key="`et-cell-${rowIndex}-${colIndex}`">
              <div v-if="hasValue(item, column)">
                {{ itemValue(item, column) }}
              </div>
            </td>
            <td v-if="hasActions">
              <router-link :to="{ name: routeName, params: { id: item.id } }" class="btn btn-info btn-xs">
                <i class="ti-eye"></i>
                Ver
              </router-link>
            </td>
          </slot>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <p class="m-4">No se encontraron resultados</p>
    </div>
    <div class="table-controls" v-if="hasPagination">
      <slot name="pagination">
        <div class="pagination-size">
          <div class="form-group">
            <label class="mr-2">Mostrar por página:</label>
            <select class="custom-select" v-model="params.perPage">
              <option v-for="ppo in perPageOptions" :value="ppo" :key="ppo">
                {{ ppo }}
              </option>
            </select>
          </div>
        </div>
        <Pagination class="pull-right" v-model="params.page" :per-page="params.perPage" :total="total"></Pagination>
      </slot>
    </div>
  </div>
</template>
<script>
import Pagination from '@/components/Pagination';
export default {
  name: 'EntityTable',
  components: {
    Pagination
  },
  props: {
    hasSearch: {
      type: Boolean,
      default: true
    },
    hasActions: {
      type: Boolean,
      default: true
    },
    hasPagination: {
      type: Boolean,
      default: true
    },
    columns: Array,
    tableData: Array,
    type: {
      type: String, // striped | hover
      default: 'striped'
    },
    routeName: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    total: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      perPageOptions: [5, 10, 25, 50],
      params: {
        search: '',
        perPage: 10,
        page: 1
      }
    };
  },
  computed: {
    tableClass() {
      return `table-${this.type}`;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.page - 1);
    }
  },
  methods: {
    hasValue(item, column) {
      return item[column.toLowerCase()] !== 'undefined';
    },
    itemValue(item, column) {
      return item[column.toLowerCase()];
    },
    refetch() {
      this.$emit('refetch', {
        'filter[search]': this.params.search,
        perPage: this.params.perPage,
        page: this.params.page
      });
    }
  },
  watch: {
    'params.search': {
      handler(newValue) {
        setTimeout(() => {
          if (newValue === this.params.search) {
            this.refetch();
          }
        }, 1200);
      },
      deep: true
    },
    'params.perPage': {
      handler() {
        this.refetch();
      },
      deep: true
    },
    'params.page': {
      handler() {
        this.refetch();
      },
      deep: true
    }
  }
};
</script>
<style lang="scss" scoped>
.filtros-container {
  padding: 20px 20px 0px;
}

.form-group.filtro {
  padding-right: 20px;
}

.table-controls {
  text-align: center;

  .pagination-size {
    margin: 0 auto;

    select {
      width: 100px;
    }
  }

  ul.pagination {
    display: inline-flex;
    float: none;
  }
}

@media only screen and (max-width: 990px) {
  table.table {
    width: auto;
  }

  table.table thead th,
  table.table tbody tr td {
    width: auto;
    white-space: nowrap;
  }
}
</style>
