// import HelloWorld from '@/components/HelloWorld'
import Login from '@/views/Usuario/Login';
import Logout from '@/views/Usuario/Logout';

import DashboardLayout from '@/layout/dashboard/DashboardLayout.vue';

// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';
// import Forbidden from '@/views/GeneralViews/ForbiddenPage.vue'

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/admin/panel'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { auth: false }
  },
  {
    path: '/forgot-password',
    name: 'RecoverKey',
    component: require('@/views/Usuario/RecoverKey').default,
    meta: { auth: false }
  },
  {
    path: '/reset-password',
    name: 'ConfigurarNuevaClave',
    component: require('@/views/ConfigurarNuevaClave').default,
    meta: { auth: false }
  },
  {
    path: '/solicitud-cliente',
    name: 'solicitud cliente',
    component: require('@/views/Contact.vue').default,
    meta: { auth: true }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    meta: { auth: true }
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/403',
    name: 'Forbidden',
    component: require('@/views/GeneralViews/ForbiddenPage.vue').default
  },
  {
    path: '/admin',
    component: DashboardLayout,
    redirect: '/admin/panel',
    meta: { auth: true },
    children: [
      {
        path: 'panel',
        name: 'panel',
        component: require('@/views/PanelPrincipal.vue').default,
        meta: { auth: { roles: ['ROLE_ADMIN', 'ROLE_SUDO'], forbiddenRedirect: '/admin/perfil' } }
      },
      {
        path: 'configuraciones',
        name: 'configuraciones',
        component: require('@/views/Settings.vue').default,
        meta: { auth: { roles: ['ROLE_ADMIN', 'ROLE_SUDO'], forbiddenRedirect: '/admin/perfil' } }
      },
      {
        path: 'simulador/:id',
        name: 'simulador',
        component: require('@/views/Simulador.vue').default,
        meta: { auth: true }
      },
      {
        path: 'perfil',
        name: 'perfil',
        component: require('@/views/Usuario/ProfileView.vue').default,
        meta: { auth: true }
      },
      {
        path: 'perfil/editar',
        name: 'editar perfil',
        component: require('@/views/Usuario/EditProfile.vue').default,
        meta: { auth: true }
      },
      {
        path: 'usuarios',
        name: 'usuarios',
        component: require('@/views/Usuarios.vue').default,
        meta: { auth: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_SUDO'] }
      },
      {
        path: 'usuarios/nuevo',
        name: 'nuevo usuario',
        component: require('@/views/Usuarios/NewView.vue').default,
        meta: { auth: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_SUDO'] }
      },
      {
        path: 'usuarios/:id/editar',
        name: 'editar usuario',
        component: require('@/views/Usuarios/EditView.vue').default,
        meta: { auth: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_SUDO'] }
      },
      {
        path: 'usuarios/:id/ver',
        name: 'ver usuario',
        component: require('@/views/Usuarios/UsersView.vue').default,
        meta: { auth: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_SUDO'] }
      },
      {
        path: 'usuarios/:id/clientes',
        name: 'clientes usuario',
        component: require('@/views/Usuarios/AssignClients.vue').default,
        meta: { auth: ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_SUDO'] }
      },
      {
        path: 'usuarios/:id/productores',
        name: 'productores usuario',
        component: require('@/views/Usuarios/AssignProducers.vue').default,
        meta: { auth: ['ROLE_ADMIN', 'ROLE_SUDO'] }
      },
      {
        path: 'clientes',
        name: 'clientes',
        component: require('@/views/Clientes.vue').default,
        meta: { auth: true }
      },
      {
        path: 'clientes/nuevo',
        name: 'nuevo cliente',
        component: require('@/views/Clientes/NewView.vue').default,
        meta: { auth: true }
      },
      {
        path: 'clientes/:id/editar',
        name: 'editar cliente',
        component: require('@/views/Clientes/EditView.vue').default,
        meta: { auth: true }
      },
      {
        path: 'clientes/:id/ver',
        name: 'ver cliente',
        component: require('@/views/Clientes/ClientView.vue').default,
        meta: { auth: true }
      },
      {
        path: 'coberturas',
        name: 'coberturas',
        component: require('@/views/Coberturas.vue').default,
        meta: { auth: ['ROLE_ADMIN', 'ROLE_SUDO'] }
      },
      {
        path: 'coberturas/nuevo',
        name: 'nueva cobertura',
        component: require('@/views/Coberturas/NewView.vue').default,
        meta: { auth: ['ROLE_ADMIN', 'ROLE_SUDO'] }
      },
      {
        path: 'coberturas/importar',
        name: 'importar coberturas',
        component: require('@/views/Coberturas/BulkImport.vue').default,
        meta: { auth: ['ROLE_ADMIN', 'ROLE_SUDO'] }
      },
      {
        path: 'coberturas/exportar',
        name: 'exportar tasas',
        component: require('@/views/Coberturas/RateExport.vue').default,
        meta: { auth: ['ROLE_ADMIN', 'ROLE_SUDO'] }
      },
      {
        path: 'coberturas/:id/ver',
        name: 'ver cobertura',
        component: require('@/views/Coberturas/View.vue').default,
        meta: { auth: ['ROLE_ADMIN', 'ROLE_SUDO'] }
      },
      {
        path: 'coberturas/:id/editar',
        name: 'editar cobertura',
        component: require('@/views/Coberturas/EditView.vue').default,
        meta: { auth: ['ROLE_ADMIN', 'ROLE_SUDO'] }
      },
      {
        path: 'coberturas-usuario',
        name: 'coberturas por usuario',
        component: require('@/views/CoberturasUsuario.vue').default,
        meta: { auth: true }
      },
      {
        path: 'negociaciones',
        name: 'cotizaciones',
        component: require('@/views/Negociaciones.vue').default,
        meta: { auth: true }
      },
      {
        path: 'negociaciones/nuevo',
        name: 'nueva cotizacion',
        component: require('@/views/Negociaciones/Nuevo.vue').default,
        meta: { auth: true }
      },
      {
        path: 'negociaciones/grupo',
        name: 'grupo cotizacion',
        component: require('@/views/Negociaciones/Grupo.vue').default,
        meta: { auth: true }
      },
      {
        path: 'negociaciones/:id/ver',
        name: 'ver cotizacion',
        component: require('@/views/Negociaciones/Ver.vue').default,
        meta: { auth: true }
      },
      {
        path: '/productores',
        name: 'productores',
        component: require('@/views/Productores.vue').default,
        meta: { auth: true }
      },
      {
        path: 'campaigns',
        name: 'campañas',
        component: require('@/views/Campaigns.vue').default,
        meta: { auth: true }
      },
      {
        path: 'campaigns/new',
        name: 'nueva campaña',
        component: require('@/views/Campaigns/New.vue').default,
        meta: { auth: true }
      },
      {
        path: 'campaigns/:id/view',
        name: 'ver campaña',
        component: require('@/views/Campaigns/View.vue').default,
        meta: { auth: true }
      },
      {
        path: 'campaigns/:id/edit',
        name: 'editar campaña',
        component: require('@/views/Campaigns/Edit.vue').default,
        meta: { auth: true }
      },
      {
        path: 'cotizacion/:id/propuesta',
        name: 'nueva propuesta',
        component: require('@/views/Proposals/New.vue').default,
        meta: { auth: true }
      },
      {
        path: 'solicitudes',
        name: 'solicitudes',
        component: require('@/views/Negociaciones/SimpleNegotiation/SimpleNegotiation.vue').default,
        meta: { auth: true }
      },
      {
        path: 'solicitudes/nuevo',
        name: 'nueva solicitud',
        component: require('@/views/Negociaciones/SimpleNegotiation/Nuevo.vue').default,
        meta: { auth: true }
      },
      {
        path: 'solicitudes/:id/ver',
        name: 'ver solicitud',
        component: require('@/views/Negociaciones/SimpleNegotiation/Ver.vue').default,
        meta: { auth: true }
      },
      {
        path: 'solicitudes',
        name: 'eliminar solicitud',
        component: require('@/views/Negociaciones/SimpleNegotiation/Ver.vue').default,
        meta: { auth: true }
      },
      {
        path: 'negociaciones/:id/editar',
        name: 'editar cotizacion',
        component: require('@/views/Negociaciones/Grupo.vue').default,
        meta: { auth: true }
      }
    ]
  }
];

export default routes;
