<template>
  <div class="row">
    <div class="col-sm-9">
      <template v-if="!newClientFormEnabled">
        <div class="form-group">
          <label>Cliente</label>
          <VueSelect
            cy-id="client-select"
            v-if="simpleNegotiation"
            :value="getSimpleNegotiationClient"
            :clearable="false"
            :options="selectorOptions"
            :disabled="true"
            @input="setCliente"
            :class="{ 'select-invalid': $v.selectedClient.$error }"
          >
            <span slot="no-options">No se encontró el cliente.</span>
          </VueSelect>
           <VueSelect
            cy-id="client-select"
            v-if="updateNegotiation"
            :value="getUpdateNegotiationClient"
            :clearable="false"
            :options="selectorOptions"
            @input="setCliente"
            :class="{ 'select-invalid': $v.selectedClient.$error }"
          >
            <span slot="no-options">No se encontró el cliente.</span>
          </VueSelect>
          <VueSelect
            v-if="updateNegotiation === null && simpleNegotiation === null"
            cy-id="client-select"
            :clearable="false"
            @input="setCliente"
            :options="selectorOptions"
            :class="{ 'select-invalid': $v.selectedClient.$error }"
          >
            <span slot="no-options">No se encontró el cliente.</span>
          </VueSelect>
          <small class="text-invalid" v-if="$v.selectedClient.$error" v-text="'Productor inválido'" />
        </div>
      </template>
      <template v-else>
        <new-client-form @change="submitNewClient" ref="newClientForm" />
      </template>
    </div>
    <div class="col-sm-3">
      <button
        v-if="!newClientFormEnabled"
        :disabled="isSimpleNegotiation"
        class="btn btn-primary mt-3 btn-block btn-margin"
        @click="toggleForm"
      >
        NUEVO CLIENTE
      </button>
      <button
        v-if="newClientFormEnabled"
        :disabled="isSimpleNegotiation"
        class="btn btn-danger mt-3 btn-block btn-margin"
        @click="toggleForm"
      >
        CANCELAR
      </button>
    </div>
  </div>
</template>
<script>
import VueSelect from '@/components/VueSelect';
import NewClientForm from '@/components/Negotiation/Forms/NewClientForm';
import { required } from 'vuelidate/lib/validators';
import { isAdmin, isSuperUser } from '@/plugins/roles.js';

export default {
  name: 'ClientForm',
  components: {
    VueSelect,
    NewClientForm
  },
  props: {
    clientId: {
      type: Number,
      default: null
    },
    producerId: {
      type: Number,
      default: null
    },
    simpleNegotiation: {
      type: Object,
      default: null
    },
    isSimpleNegotiation: {
      type: Boolean,
      default: false
    },
    isSUpdateNegotiation: {
      type: Boolean,
      default: false
    },
    updateNegotiation: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selectedClient: this.clientId,
      clients: [],
      newClientFormEnabled: false,
      newClient: {
        cuit: '',
        name: '',
        lastname: ''
      }
    };
  },
  mounted() {
    this.fetchClients();
  },
  validations: {
    selectedClient: {
      required
    }
  },
  computed: {
    selectorOptions() {
      return this.clients.map(client => ({
        label: this.labelCliente(client),
        value: client.id
      }));
    },
    getSimpleNegotiationClient() {
      return {
        label: this.simpleNegotiation.client.name? this.simpleNegotiation.client.name : this.simpleNegotiation.client.lastname,
        value: this.simpleNegotiation.client.id
      };
    },
    getUpdateNegotiationClient() {
      return {
        label: this.updateNegotiation.client.name? this.updateNegotiation.client.name : this.updateNegotiation.client.lastname,
        value: this.updateNegotiation.client.id
      };
    }
  },
  methods: {
    toggleForm() {
      if (this.newClientFormEnabled) {
        this.$refs.newClientForm.resetFields();
      } else {
        this.selectedClient = '';
      }

      this.newClientFormEnabled = !this.newClientFormEnabled;

      this.$emit('change', null);
    },
    validate() {
      if (this.newClientFormEnabled) {
        return this.$refs.newClientForm.validate();
      } else {
        this.$v.$touch();
        return !this.$v.$invalid;
      }
    },
    fetchClients() {
      if (isAdmin(this.$auth.user()) || isSuperUser(this.$auth.user())) {
        this.$api.clients.getAll().then(response => {
          this.clients = response.data.clients;
        });
      } else {
        this.$api.users.getClients(this.$auth.user().id).then(response => {
          this.clients = response.data.clients;
        });
      }
    },
    setCliente(selectedOption) {
      this.selectedClient = selectedOption.value;
      this.$emit('change', this.selectedClient);
    },
    submitNewClient(formData) {
      this.newClient = formData;
      this.$emit('change', this.newClient);
    },
    labelCliente(client) {
      let name = client.name ? `${client.name} ${client.lastname}` : client.lastname;
      let cuit = client.cuit ? client.cuit : '';

      return `${name}( ${cuit} )`;
    }
  },
  watch: {
    producerId(newValue) {
      this.fetchClients(newValue);
    }
  }
};
</script>
<style lang="scss" scoped>
html {
  scroll-behavior: smooth;
}
@media (min-width: 724px) {
  .mt-3 {
    margin-top: 1.6rem !important;
  }
}
.select-invalid {
  border: 1px solid #dc3545;
  border-radius: 5px;
}
.text-invalid {
  color: #dc3545;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
}
</style>
