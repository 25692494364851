<template>
  <div class="row justify-content-between align-items-center">
    <div class="col">
      <p class="font-weight-bold">Cultivo</p>
      <p class="info">{{ cropNames[calculatedData.crop_id] }}</p>
    </div>
    <div class="col">
      <p class="font-weight-bold">Capital</p>
      <p class="info">{{ capital }}</p>
    </div>
     <div class="col">
      <p class="font-weight-bold">TNA Pesos</p>
      <p class="info">{{ calculatedData.pesos.tna_pesos }}%</p>
    </div>
     <div class="col">
      <p class="font-weight-bold">TNA Dólares</p>
      <p class="info">{{ calculatedData.pesos.tna_dollars }}%</p>
    </div>
    <div class="col-span-2 p-2">
      <p class="font-weight-bold">Fecha de operación</p>
      <p class="info">{{ getTodayDate() }}</p>
    </div>
    <div class="col-span-2 p-2">
      <p class="font-weight-bold w-7">Fecha vencimiento cultivo</p>
      <p class="info">{{ calculatedData.pesos.crop_exp_date }}</p>
    </div>
    <div class="col-span-2 p-2">
      <p class="font-weight-bold">Fecha vencimiento dólar</p>
      <p class="info">{{ calculatedData.pesos.dollar_exp_date }}</p>
    </div>
  </div>
</template>
<script>
import { CROP_NAMES } from '@/utils/general.js';

export default {
  props: {
    calculatedData: {
      required: true,
      type: Object
    },
    capital: {
      required: true,
      type: String
    }
  },

  data() {
    return {
      cropNames: CROP_NAMES
    };
  },

  methods: {
    getTodayDate() {
      const fechaActual = new Date();
      const año = fechaActual.getFullYear();
      const mes = String(fechaActual.getMonth() + 1).padStart(2, '0'); // Se agrega 1 porque los meses van de 0 a 11
      const dia = String(fechaActual.getDate()).padStart(2, '0');
      return `${año}-${mes}-${dia}`;
    }
  }
};
</script>
<style scoped>
.info {
  color: #656565;
  display: flex;
  justify-content: center;
}
.col {
  text-align: center;
}
</style>
