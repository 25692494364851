export const generateEmptyRisk = (id = 1) => ({
  crop: null,
  description: '',
  hectarePrice: 0,
  hectarePriceCurrencyId: 0,
  numberOfHectares: 0,
  doubleCropRisk: {},
  id,
  zone: null,
});
