const EXCHANGE = 1;
const FINANCED = 2;
const KIND = 3;
const FINANCED_KIND = 4;
const WITH_ADJUSTMENT = 5;

const EXCHANGE_LABEL = 'Canje';
const FINANCED_LABEL = 'Financiado';
const KIND_LABEL = 'Pago en Especie';
const FINANCED_KIND_LABEL = 'Pago en Especie Financiado';
const WITH_ADJUSTMENT_LABEL = 'Quintales Con Ajuste';

const WITHVAT = 1;
const WITHOUTVAT = 2;
const BEFORETAXES = 3;

export default {
  EXCHANGE,
  FINANCED,
  KIND,
  FINANCED_KIND,
  WITH_ADJUSTMENT,

  EXCHANGE_LABEL,
  FINANCED_LABEL,
  KIND_LABEL,
  FINANCED_KIND_LABEL,
  WITH_ADJUSTMENT_LABEL,

  WITHOUTVAT,
  WITHVAT,
  BEFORETAXES,

  METHODS: [
    {
      code: EXCHANGE,
      label: 'Canje',
      rateOptions: [
        { code: WITHOUTVAT, label: 'Premio SIN IVA' },
        { code: WITHVAT, label: 'Premio CON IVA' }
      ]
    },
    {
      code: FINANCED,
      label: 'Financiado',
      rateOptions: [
        { code: WITHOUTVAT, label: 'Premio SIN IVA' },
        { code: WITHVAT, label: 'Premio CON IVA' }
      ]
    },
    {
      code: KIND,
      label: 'Pago en Especie',
      rateOptions: [{ code: BEFORETAXES, label: 'Premio antes de impuestos' }]
    },
    {
      code: FINANCED_KIND,
      label: 'Pago en Especie Financiado',
      rateOptions: [{ code: BEFORETAXES, label: 'Premio antes de impuestos' }]
    },
    {
      code: WITH_ADJUSTMENT,
      label: 'Quintales Con Ajuste',
      rateOptions: [
        { code: WITHOUTVAT, label: 'Premio SIN IVA' },
        { code: WITHVAT, label: 'Premio CON IVA' }
      ]
    }
  ]
};
