import utils from '@/plugins/utils';

export const validateAlpha = value => {
  const regExp = /^[a-zA-ZÀ-ÿ\d\s.,-°]+$/;
  return regExp.test(value) ? true : false;
};

export const validateCuit = valueCuit => {
  return !utils.isWrongCuit(valueCuit);
};

export const validateFutureDate = value => {
  const today = new Date();
  const date = new Date(value);
  today.setFullYear(today.getFullYear() + 1);
  return date <= today;
};
