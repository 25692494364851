import RestResource from '../rest.service';
export default class FinishNegotiationService extends RestResource {
  map(payload) {
    return {
      splitCoverages: payload.splitCoverages,
      risks: payload.risks,
      modules: payload.modules,
      payment_method_id: payload.configurations.paymentMethod,
      rate_option_id: payload.configurations.rateOption,
      correction_factor: payload.configurations.correctionFactor,
      fd_filter: payload.fdFilter,
      state: payload.state
    };
  }

  finishNegotiation(negotiationId, payload) {
    return this.post(`${this.route}/${negotiationId}`, this.map(payload));
  }

  constructor(api) {
    super(api, 'negotiations', 'api');
  }
}
