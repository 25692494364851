<template>
  <div v-if="hasPermission()">
    <div v-if="isDataLoaded" class="simulador">
      <simulator-parameters
        :client="client"
        :dollars="dollars"
        :params="params"
        :negotiationId="negotiationId"
        :hideEdit="hideEdit"
        :daily-parameters="dailyParameters"
        :futures="futureCropQuotation"
        @parameters="saveParameters"
      />
      <tabs>
        <tab-pane title="Simulador">
          <simulador-principal :risks="items" :daily-parameters="dailyParameters" :summarized="calculus.modules" />
        </tab-pane>
        <tab-pane title="Pago Contado">
          <cash :future-crop="futureCrop" :risks="items" :daily-parameters="dailyParameters" />
        </tab-pane>
        <tab-pane title="Resumen">
          <simulator-summary
            :risks="items"
            :daily-parameters="dailyParameters"
            :params="params"
            @filterQuotations="filterQuotations"
          />
        </tab-pane>
        <tab-pane title="Valores Externos">
          <external-values
            :future-crop="futureCrop"
            :risks="items"
            :daily-parameters="dailyParameters"
            :params="params"
            :dollars="dollars"
            :future-crop-quotation="futureCropQuotation"
          />
        </tab-pane>
        <tab-pane title="Exportar">
          <card>
            <div class="m-5 row">
              <div class="col-6 text-center">
                <p>
                  Haga click abajo si desea descargar el documento en PDF
                  <i class="ti-file"></i>
                </p>
                <button class="btn btn-danger" @click="downloadPDF">
                  Descargar PDF
                  <i class="ti-download"></i>
                </button>
              </div>
              <div class="col-6 text-center">
                <p>
                  Haga click abajo si desea descargar el documento en Excel
                  <i class="ti-microsoft"></i>
                </p>
                <button class="btn btn-success" @click="downloadExcel">
                  Descargar Excel
                  <i class="ti-download"></i>
                </button>
              </div>
            </div>
          </card>
        </tab-pane>
        <tab-pane title="Financiación" @isActive="hideEditParams">
          <refinancing :negotiationId="negotiationId" />
        </tab-pane>
      </tabs>
    </div>
  </div>
  <div v-else class="alert alert-warning alert-with-icon">
    <span data-notify="icon" class="ti-lock p-2" style="font-size: 1.5rem"></span>
    <span data-notify="message">No tiene permiso para ver esta página</span>
  </div>
</template>

<script>
import SimuladorPrincipal from './Simulador/SimuladorPrincipal';
import Cash from './Simulador/Cash';
import ExternalValues from './Simulador/ExternalValues';
import Refinancing from './Simulador/Refinancing';
import SimulatorSummary from './Simulador/SimulatorSummary';

import SimulatorParameters from '@/components/Negotiation/Simulator/Parameters';

import Tabs from '@/components/Tabs/Tabs';
import TabPane from '@/components/Tabs/Tab';
import { CROP_IDS, CROPS_RECHARGES } from '@/utils/general';
import rechargeRates from '@/mixins/rechargeRates';
import { isAdmin, isSuperUser, isSupervisor } from '@/plugins/roles.js';
import { downloadBlob } from '@/plugins/files.js';

export default {
  name: 'SimulatorIndex',
  components: {
    Tabs,
    TabPane,
    SimuladorPrincipal,
    SimulatorParameters,
    Cash,
    ExternalValues,
    SimulatorSummary,
    Refinancing
  },

  data() {
    return {
      dailyParameters: null,
      client: null,
      risks: [],
      items: [],
      dollars: [],
      settings: [],
      thinRate: null,
      thickRate: null,
      applyAllThickRates: null,
      applyAllThinRates: null,
      applyAllThinDates: null,
      applyAllThickDates: null,
      thickCropDate: null,
      thinCropDate: null,
      cornCropDate: null,
      soyCropDate: null,
      wheatCropDate: null,
      sunflowerCropDate: null,
      soyRate: null,
      cornRate: null,
      sunflowerRate: null,
      wheatRate: null,
      applyQuotationsManually: null,
      soyQuotation: null,
      cornQuotation: null,
      sunflowerQuotation: null,
      wheatQuotation: null,
      selectedFutureValue: null,
      futureDollar: null,
      futureDollarId: null,
      cropsRecharges: CROPS_RECHARGES,
      futureCrop: {},
      futureDollars: [],
      calculus: {},
      futureCropQuotation: {},
      params: {},
      filteredQuotations: [],
      hideEdit: false
    };
  },

  computed: {
    isDataLoaded() {
      return this.items.length > 0 && Object.keys(this.futureCrop).length > 0;
    },

    negotiationId() {
      return parseInt(this.$route.params.id);
    }
  },

  async mounted() {
    await this.fetchSettings();
    await this.getCalculus(this.params);
    await this.fetchData();
    await this.fetchFutures();
    await this.fetchFutureDollars();
  },

  methods: {
    hideEditParams(state) {
      console.log(state);
      this.hideEdit = state; // Update the hideEdit data property with the new value
      return state;
    },

    getCalculus(params) {
      this.$api.simulator.getCalculus(this.negotiationId, params).then(res => {
        this.calculus = res;
        this.futureCropQuotation = res.rofex.crops;
        if (this.calculus.quotations.some(item => item.futureCrop == 0)) {
          let crops = this.calculus.quotations.filter(item => item.futureCrop == 0).map(item => item.crop);
          crops = Object.values(crops.reduce((prev, next) => Object.assign(prev, { [next]: next }), {}));
          let errorText = '<p>No se encontro futuro para la fecha de vencimiento indicada en los cultivos: </p>';
          let cropList = '<ul  style="list-style-type:none; padding:0;">';
          crops.forEach(c => {
            cropList += '<li>' + c + '</li>';
          });
          cropList += '</ul>';
          this.$swal({
            title: 'Advertecia!',
            html: errorText + cropList,
            type: 'warning',
            footer: ''
          });
        }
      });
    },
    filterQuotations(quotations) {
      this.filteredQuotations = quotations;
    },
    async fetchFutures() {
      this.futureCrop = (
        await Promise.all(
          Object.values(CROP_IDS).map(cropId => this.$api.simulator.getFuture(cropId, this.getMonth(cropId)))
        )
      ).reduce((carry, future, index) => ({ ...carry, [Object.keys(CROP_IDS)[index]]: future }), {});
    },

    async fetchFutureDollars() {
      return this.$api.simulator
        .getAll({
          perPage: 13
        })
        .then(res => {
          this.futureDollars = res.data.futures
            .filter(element => {
              return (
                element.option === 'DLRMATBAROFEX' ||
                (element.option.startsWith('DLR') && this.checkDollarDate(element.option))
              );
            })
            .map(futures => ({
              label: futures.option + '-' + futures.value,
              value: futures.value,
              id: futures.id
            }));
          this.dollars = this.futureDollars.filter(element => element.label.startsWith('DLR'));
        });
    },

    checkDollarDate(element) {
      return (
        (element.substr(5) == new Date().getFullYear() && element.substr(3, 2) >= new Date().getMonth() + 1) ||
        element.substr(5) > new Date().getFullYear()
      );
    },

    getMonth(cropId) {
      if (cropId == CROP_IDS['soy']) {
        if (Number(this.params.applyAllThickDates == 0)) {
          return this.params.thickCropDate.slice(5, 7);
        } else {
          return this.params.soyCropDate.slice(5, 7);
        }
      } else if (cropId == CROP_IDS['corn']) {
        if (Number(this.params.applyAllThickDates == 0)) {
          return this.params.thickCropDate.slice(5, 7);
        } else {
          return this.params.cornCropDate.slice(5, 7);
        }
      } else if (cropId == CROP_IDS['wheat']) {
        if (Number(this.params.applyAllThinDates == 0)) {
          return this.params.thinCropDate.slice(5, 7);
        } else {
          return this.params.wheatCropDate.slice(5, 7);
        }
      }
    },

    setSettings() {
      const thickCropDate = this.getOption('thick_crop_date').value;
      const thinCropDate = this.getOption('thin_crop_date').value;
      const soyCropDate = this.getOption('soy_crop_date').value;
      const cornCropDate = this.getOption('corn_crop_date').value;
      const sunflowerCropDate = this.getOption('sunflower_crop_date').value;
      const wheatCropDate = this.getOption('wheat_crop_date').value;
      const thickDollarDate = this.getOption('thick_crop_date').value;
      const thinDollarDate = this.getOption('thin_crop_date').value;
      const soyDollarDate = this.getOption('soy_crop_date').value;
      const cornDollarDate = this.getOption('corn_crop_date').value;
      const wheatDollarDate = this.getOption('wheat_crop_date').value;

      const dateFixer = value => {
        if (new Date(value) < new Date()) {
          let date = new Date();

          date.setDate(date.getDate() + 2);

          const month = (date.getMonth() + 1).toString().padStart(2, '0');
          const day = date.getDate().toString().padStart(2, '0');

          return `${date.getFullYear()}-${month}-${day}`;
        } else {
          return value;
        }
      };

      this.params = {
        thickRate: this.getOption('thick_recharge_rate').value,
        thinRate: this.getOption('thin_recharge_rate').value,
        soyRate: this.getOption('soy_recharge_rate').value,
        cornRate: this.getOption('corn_recharge_rate').value,
        sunflowerRate: this.getOption('sunflower_recharge_rate').value,
        wheatRate: this.getOption('wheat_recharge_rate').value,
        applyAllThinRates: Number(this.getOption('apply_all_thin_rates').value),
        applyAllThickRates: Number(this.getOption('apply_all_thick_rates').value),
        applyAllThickDates: Number(this.getOption('apply_all_thick_dates').value),
        applyAllThinDates: Number(this.getOption('apply_all_thin_dates').value),
        thickCropDate: dateFixer(thickCropDate),
        thinCropDate: dateFixer(thinCropDate),
        soyCropDate: dateFixer(soyCropDate),
        cornCropDate: dateFixer(cornCropDate),
        sunflowerCropDate: dateFixer(sunflowerCropDate),
        wheatCropDate: dateFixer(wheatCropDate),
        thickDollarDate: dateFixer(thickDollarDate),
        thinDollarDate: dateFixer(thinDollarDate),
        soyDollarDate: dateFixer(soyDollarDate),
        wheatDollarDate: dateFixer(wheatDollarDate),
        cornDollarDate: dateFixer(cornDollarDate),
        applyQuotationsManually: Number(this.getOption('apply_quotations_manually').value),
        soyQuotation: this.getOption('soy_quotation'),
        cornQuotation: this.getOption('corn_quotation'),
        sunflowerQuotation: this.getOption('sunflower_quotation'),
        wheatQuotation: this.getOption('wheat_quotation'),
        selectedFutureValue: this.getOption('selected_future').value
      };
    },

    async fetchSettings() {
      return this.$api.settings.getAll({ perPage: 100 }).then(res => {
        this.settings = res.data.settings;
        this.setSettings(this.settings);
      });
    },

    getOption(setting) {
      return this.settings.find(s => s.option === setting);
    },

    async fetchData() {
      const response = await this.$api.simulator.getSelectedFuture();
      this.futureDollar = response.data.value;
      this.futureDollarId = response.data.id;

      const dailyParameters = await this.$api.simulator.getLastParameters();
      this.dailyParameters = {
        futureDollar: this.futureDollar,
        futureDollarId: this.futureDollarId,
        applyAllThickRates: this.params.applyAllThickRates,
        applyAllThinRates: this.params.applyAllThinRates,
        soyRate: this.params.soyRate,
        cornRate: this.params.cornRate,
        sunflowerRate: this.params.sunflowerRate,
        wheatRate: this.params.wheatRate,
        thickRate: this.params.thickRate,
        thinRate: this.params.thinRate,
        applyQuotationsManually: this.params.applyQuotationsManually,
        soyQuotation: this.params.soyQuotation,
        cornQuotation: this.params.cornQuotation,
        sunflowerQuotation: this.params.sunflowerQuotation,
        wheatQuotation: this.params.wheatQuotation,

        ...dailyParameters.data
      };

      const { data } = await this.$api.negotiations.getOne(this.$route.params.id);
      data.risks
        .filter(item => [1, 2, 4].includes(item.cropId))
        .forEach(risk => {
          this.items = [
            ...this.items,
            ...risk.quotations.map(quotation => ({
              crop: risk.crop.description,
              cropId: risk.cropId,
              zone: risk.zone.description + ' / ' + risk.zone.province.name,
              harvest: risk.crop.harvest,
              hectares: risk.numberOfHectares,
              hectarePrice: risk.hectarePrice,
              hectarePriceCurrency: risk.hectarePriceCurrency.name,
              insuredTotal: risk.numberOfHectares * risk.hectarePrice,
              quotation,
              calculatedQuotation: this.calculus.quotations.find(q => q.quotationId === quotation.id)
            }))
          ];
        });
      this.client = data.client.name ? data.client.name + ' ' + data.client.lastname : data.client.lastname;
    },

    downloadPDF() {
      this.params['filteredQuotations'] = this.filteredQuotations;
      this.$api.simulator
        .getPdf(this.negotiationId, this.params)
        .then(response => {
          const fileName = `Informe Simulación Negociación Nro:${this.negotiationId}.pdf`;
          downloadBlob(new Blob([response]), fileName);
        })
        .catch(error => {
          console.error(error);
        });
    },

    downloadExcel() {
      this.params['filteredQuotations'] = this.filteredQuotations;
      this.$api.simulator
        .getExcel(this.negotiationId, this.params)
        .then(response => {
          const fileName = `Informe Simulación Negociación Nro:${this.negotiationId}.xlsx`;
          downloadBlob(new Blob([response]), fileName);
        })
        .catch(error => {
          console.error(error);
        });
    },

    async saveParameters(params) {
      this.params = params;
      await this.getCalculus(params);
      this.items = [];
      await this.fetchFutures();
      await this.fetchData();
    },

    hasPermission() {
      return (
        this.$auth.user().see_simulator ||
        isAdmin(this.$auth.user()) ||
        isSuperUser(this.$auth.user()) ||
        isSupervisor(this.$auth.user())
      );
    }
  }
};
</script>

<style lang="scss">
.simulador {
  .nav-tabs-navigation {
    margin-bottom: 0px;

    .nav-tabs {
      @media only screen and (max-width: 600px) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }

      .nav-item {
        margin-left: 20px;
        color: #666;

        @media only screen and (max-width: 600px) {
          margin: 5px 0;
        }

        .nav-link {
          // border: 5px solid #41b883;
          background-color: rgba(248, 247, 245, 1);
          color: #666;
          -webkit-box-shadow: 0px -1px 4px -3px rgba(204, 197, 185, 0.5);
          box-shadow: 0px -1px 4px -3px rgba(204, 197, 185, 0.5);
          border-radius: 10px 10px 0px 0px;

          &:hover {
            color: #41b883;
          }

          &:focus {
            color: #fff;
          }

          &.active {
            background-color: #fff;
            color: #41b883;
          }

          @media only screen and (max-width: 600px) {
            border-radius: 0px;
          }
        }
      }
    }
  }
}
</style>
