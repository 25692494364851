<template>
  <div>
    <transition name="slide">
      <!-- accept quotation - add comment -->
      <div class="" v-if="showAcceptQuotationForm">
        <p>
          Cotización a aceptar:
          {{ acceptedQuotation.quotation.rate.module.description }}
          -
          {{ acceptedQuotation.quotation.rateValueWithCf | rateFormat }}
        </p>
        <textarea
          class="form-control"
          v-model="acceptedQuotation.comment"
          placeholder="Agrega tu comentario"
          rows="3"
        ></textarea>
        <div class="button-container">
          <button class="btn btn-secondary" @click="resetAcceptQuotationForm">
            <i class="ti-close"></i>
            Cancelar
          </button>
          <button cy-id="confirm-quotation" class="btn btn-success" @click="confirmAccept">
            <i class="ti-check"></i>
            Confirmar
          </button>
        </div>
      </div>
    </transition>

    <transition name="slide">
      <div class="table-responsive" v-show="!showAcceptQuotationForm">
        <table class="table tabla-cotizaciones">
          <thead>
            <th colspan="2">Cobertura</th>
            <th>{{ rateOption | opcionTasa }} sin adicional</th>
            <th>Adicionales</th>
            <th>{{ rateOption | opcionTasa }}</th>
            <th>Costo Total {{(paymentMethod == 3 || paymentMethod == 4) ? "" : rateOption | iva}}({{ unidadValor | unidadValorHA }})</th>
            <th>Costo Total por Hectarea {{(paymentMethod == 3 || paymentMethod == 4) ? "" : rateOption | iva}} ({{ unidadValor | unidadValorHA }})</th>
            <th v-if="seeActions && !negotiation?.client?.deletedAt">Acciones</th>
          </thead>
          <tbody>
            <template v-for="item in dataTable">
              <tr
                v-for="(cot, index) in item.quotations"
                :key="`quotation-${getModuleFormatted(item)}-${index}`"
                :class="getRowColor(cot)"
              >
                <td v-if="index === 0" class="col-coverage" :rowspan="item.quotations.length">
                  {{ item.modules }}
                  <span class="d-block" v-for="addon in cot.addons" :key="`addon-${cot.id}-${addon.id}`">
                    <em>+ {{ addon.module.description }}</em>
                  </span>
                </td>
                <td>{{ cot.rate.franchiseDeductible }}</td>
                <td class="col-prima">
                  {{ (cot.rateValueWithCf - cot.addonsValueWithCf) | rateFormat }}
                </td>
                <td class="col-prima">
                  {{ cot.addonsValueWithCf | rateFormat }}
                </td>
                <td class="col-prima col-prima-total">
                  {{ cot.rateValueWithCf | rateFormat }}
                </td>
                <td class="col-costs">{{ cot.cost | formatoValor }}</td>
                <td class="col-costs">
                  {{ cot.costPerHectare | formatoValor }}
                </td>

                <td class="col-actions" v-if="seeActions && !negotiation?.client?.deletedAt">
                  <div class="button-colum">
                    <button
                      class="btn btn-xs btn-warning"
                      @click="$emit('recotizar', cot)"
                      v-if="hideRejectedQuotationButton(cot) && !hideButtons"
                    >
                      <i class="ti-reload"></i>
                    </button>
                    <button
                      class="btn btn-xs btn-danger"
                      @click="$emit('cancelar', cot)"
                      v-if="hideRejectedQuotationButton(cot) && !hideButtons"
                    >
                      <i class="ti-close"></i>
                    </button>
                    <button
                      cy-id="accept-quotation"
                      class="btn btn-xs btn-success"
                      @click="acceptQuotation(cot)"
                      v-if="hideRejectedQuotationButton(cot) && !hideButtons"
                    >
                      <i class="ti-check"></i>
                      Aceptar
                    </button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: 'TablaCotizaciones',
  props: {
    negotiation: Object,
    quotations: Array,
    estado: String,
    paymentMethod: Number,
    rateOption: Number,
    unidadValor: Number,
    seeActions: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false,
      acceptedQuotation: {
        comment: '',
        quotation: {}
      },
      showAcceptQuotationForm: false
    };
  },
  created() {},
  computed: {
    hideButtons() {
      let hideButtons = this.$props.quotations.some(q =>
        q.quotationStatuses.some(qs => qs.description === 'accepted' || qs.description === 'advanced')
      );

      return hideButtons;
    },
    coberturas() {
      return [...new Set(this.orderQuotations.map(a => a.rate.module.description))];
    },
    orderQuotations() {
      return this.$props.quotations.sort((a, b) => {
        if (a.rate.module.sortOrder === undefined) {
          a.rate.module.sortOrder = 999;
        }
        if (a.rate.module.sortOrder === undefined) {
          a.rate.module.sortOrder = 999;
        }
        return a.rate.module.sortOrder > b.rate.module.sortOrder
          ? 1
          : b.rate.module.sortOrder > a.rate.module.sortOrder
          ? -1
          : 0;
      });
    },
    dataTable() {
      let data = [];

      this.coberturas.forEach(cob => {
        data.push({
          modules: cob,
          quotations: this.filterCoverage(cob)
        });
      });
      return data;
    },
    getModuleFormatted() {
      return item => item.modules.split(' ').join('_');
    }
  },
  methods: {
    filterCoverage(coverage) {
      return this.orderQuotations
        .filter(dataItem => {
          if (dataItem.rate.module.description == coverage) {
            return true;
          } else {
            return false;
          }
        })
        .sort((a, b) => {
          return a.rate.franchiseDeductible > b.rate.franchiseDeductible
            ? 1
            : b.rate.franchiseDeductible > a.rate.franchiseDeductible
            ? -1
            : 0;
        });
    },
    getRowColor(quotation) {
      if (quotation.quotationStatuses.some(qs => qs.description === 'accepted')) {
        return 'table-success';
      }
      if (quotation.quotationStatuses.some(qs => qs.description === 'rejected')) {
        return 'table-danger';
      }
    },
    acceptQuotation(q) {
      this.showAcceptQuotationForm = true;
      this.acceptedQuotation.quotation = q;
    },
    resetAcceptQuotationForm() {
      this.showAcceptQuotationForm = false;
      this.acceptedQuotation.comment = '';
      this.acceptedQuotation.quotation = {};
    },
    confirmAccept() {
      this.$emit('aceptar', this.acceptedQuotation);
      this.resetAcceptQuotationForm();
    },
    canSubmitProposal(quotation) {
      return (
        quotation.quotationStatuses.find(qs => qs.description == 'accepted') &&
        quotation.rate.module.sancorId !== undefined &&
        quotation.proposal === undefined &&
        (this.$auth.check('ROLE_ADMIN') || this.$auth.check('ROLE_SUDO'))
      );
    },
    canGetAdvancePDF(quotation) {
      return (
        quotation.proposal == undefined &&
        (quotation.quotationStatuses.find(qs => qs.description == 'advanced') ||
          (quotation.quotationStatuses.find(qs => qs.description == 'accepted') && this.estado == 'anticipada'))
      );
    },
    hideRejectedQuotationButton(cot) {
      return !cot.quotationStatuses.find(qs => qs.description === 'rejected');
    }
  }
};
</script>
<style lang="scss" scoped>
.tabla-cotizaciones {
  td,
  th {
    border: 1px solid #ccc5b9;

    &:first-child {
      border-left: 0px;
    }
    &:last-child {
      border-right: 0px;
    }
  }
  th {
    text-align: center;
    vertical-align: middle;
  }
  .col-coverage {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    background-color: #fafafa;
  }
  .col-prima {
    text-align: center;
    width: 100px;

    &.col-prima-total {
      font-weight: bold;
    }
  }
  .col-costs {
    text-align: right;
    width: 100px;
  }
  .col-actions {
    text-align: center;
    background-color: #fafafa;
    @media (max-width: 600px) {
      flex-direction: column;
      .button-colum {
        display: flex;
        flex-direction: column;
        > * {
          margin: 5px 0 5px 0;
        }
      }
    }
  }
}

.slide-enter-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.5s;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 200px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
@media only screen and (max-width: 1358px) {
  .table-responsive {
    overflow-x: scroll;
  }
  .table-responsive .table {
    width: 1358px;
  }
}
@media only screen and (max-width: 990px) {
  .col-actions {
    width: 250px;
    max-width: 250px;
  }
  .table-responsive .table {
    width: 1000px;
  }
}
@media only screen and (max-width: 600px) {
  .button-container {
    display: flex;
    flex-direction: column;
  }
  .button-container .btn {
    width: 100%;
    margin: 5px 0;
  }
}
</style>
