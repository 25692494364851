var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.calculatedData)?_c('div',{staticClass:"tabla-contenedor"},[_c('div',{staticClass:"w-100"},[_c('table',{staticClass:"table w-100"},[_vm._m(0),_c('tbody',[_c('tr',[_c('td',[_vm._v("Cuota total")]),_c('td',{staticClass:"text-right"},[_vm._v("$"+_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.pesos.total).toFixed(2))))]),_c('td',{staticClass:"text-right"},[_vm._v("USD "+_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.dollars.total).toFixed(2))))])]),_c('tr',[_c('td',[_vm._v("Cuota total en pesos según cotización futuro:")]),_c('td',{staticClass:"text-right"}),_c('td',{staticClass:"text-right"},[_vm._v("$"+_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.dollars.total_pesos).toFixed(2))))])]),_c('tr',[_c('td',[_vm._v("TNA")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.pesos.tna_pesos).toFixed(2)))+"%")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.dollars.tna_dollars).toFixed(2)))+"%")])]),_c('tr',[_c('td',[_vm._v("QQ a la fecha de hoy")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.pesos.qq_hoy).toFixed(2)))+" QQ")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.dollars.qq_hoy).toFixed(2)))+" QQ")])]),_c('tr',[_c('td',[_vm._v("QQ a la fecha de vencimiento")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.pesos.qq_fecha_vencimiento).toFixed(2)))+" QQ ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.dollars.qq_fecha_vencimiento).toFixed(2)))+" QQ ")])]),_c('tr',[_c('td',[_vm._v("Tasa bullet a la fecha de vencimiento en QQ")]),_c('td',{staticClass:"text-right",class:{
              positive: _vm.calculatedData.pesos.tasa_bullet_fecha_vencimiento < 0,
              negative: _vm.calculatedData.pesos.tasa_bullet_fecha_vencimiento > 0
            }},[_vm._v(" "+_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.pesos.tasa_bullet_fecha_vencimiento).toFixed(2)))+"% ")]),_c('td',{staticClass:"text-right",class:{
              positive: _vm.calculatedData.dollars.tasa_bullet_fecha_vencimiento < 0,
              negative: _vm.calculatedData.dollars.tasa_bullet_fecha_vencimiento > 0
            }},[_vm._v(" "+_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.dollars.tasa_bullet_fecha_vencimiento).toFixed(2)))+"% ")])]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.calculatedData.pesos.ahorro_ganancias),expression:"calculatedData.pesos.ahorro_ganancias"}]},[_c('td',[_vm._v("Ahorro impuestos a las ganancias (potencial)")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.pesos.ahorro_ganancias).toFixed(2)))+" QQ ")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.dollars.ahorro_ganancias).toFixed(2)))+" QQ")])]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.calculatedData.pesos.tasa_bullet_ganancias),expression:"calculatedData.pesos.tasa_bullet_ganancias"}]},[_c('td',[_vm._v("Tasa bullet con impacto ganancias")]),_c('td',{staticClass:"text-right",class:{
              positive: _vm.calculatedData.pesos.tasa_bullet_ganancias < 0,
              negative: _vm.calculatedData.pesos.tasa_bullet_ganancias > 0
            }},[_vm._v(" "+_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.pesos.tasa_bullet_ganancias).toFixed(2)))+"% ")]),_c('td',{staticClass:"text-right",class:{
              positive: _vm.calculatedData.dollars.tasa_bullet_ganancias < 0,
              negative: _vm.calculatedData.dollars.tasa_bullet_ganancias > 0
            }},[_vm._v(" "+_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.dollars.tasa_bullet_ganancias).toFixed(2)))+"% ")])]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.calculatedData.pesos.ahorro_iva),expression:"calculatedData.pesos.ahorro_iva"}]},[_c('td',[_vm._v("Ahorro IVA ")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.pesos.ahorro_iva).toFixed(2)))+" QQ")]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.dollars.ahorro_iva).toFixed(2)))+" QQ")])]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.calculatedData.pesos.tasa_bullet_iva),expression:"calculatedData.pesos.tasa_bullet_iva"}]},[_c('td',[_vm._v("Tasa bullet con impacto IVA")]),_c('td',{staticClass:"text-right",class:{
              positive: _vm.calculatedData.pesos.tasa_bullet_iva < 0,
              negative: _vm.calculatedData.pesos.tasa_bullet_iva > 0
            }},[_vm._v(" "+_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.pesos.tasa_bullet_iva).toFixed(2)))+"% ")]),_c('td',{staticClass:"text-right",class:{
              positive: _vm.calculatedData.dollars.tasa_bullet_iva < 0,
              negative: _vm.calculatedData.dollars.tasa_bullet_iva > 0
            }},[_vm._v(" "+_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.dollars.tasa_bullet_iva).toFixed(2)))+"% ")])]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.calculatedData.pesos.cotiz_fecha_vencimiento),expression:"calculatedData.pesos.cotiz_fecha_vencimiento"}]},[_c('td',[_vm._v("Grano futuro")]),_c('td',{staticClass:"text-right"},[_vm._v(" $ "+_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.pesos.cotiz_fecha_vencimiento).toFixed(2)))+" ")]),_c('td')]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.calculatedData.pesos.dollars_today),expression:"calculatedData.pesos.dollars_today"}]},[_c('td',[_vm._v("Dólares hoy")]),_c('td',{staticClass:"text-right"},[_vm._v(" USD "+_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.pesos.dollars_today).toFixed(2)))+" ")]),_c('td')]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.calculatedData.pesos.dollars_exp_date),expression:"calculatedData.pesos.dollars_exp_date"}]},[_c('td',[_vm._v("Dólares a la fecha de vencimiento")]),_c('td',{staticClass:"text-right"},[_vm._v(" USD "+_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.pesos.dollars_exp_date).toFixed(2)))+" ")]),_c('td')]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.calculatedData.pesos.tasa_bullet_exp_date),expression:"calculatedData.pesos.tasa_bullet_exp_date"}]},[_c('td',[_vm._v("Tasa en dólares bullet a la fecha de vencimiento")]),_c('td',{staticClass:"text-right",class:{
              positive: _vm.calculatedData.pesos.tasa_bullet_exp_date < 0,
              negative: _vm.calculatedData.pesos.tasa_bullet_exp_date > 0
            }},[_vm._v(" "+_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.pesos.tasa_bullet_exp_date).toFixed(2)))+"% ")]),_c('td')]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.calculatedData.pesos.tasa_bullet_ganancias_exp_date),expression:"calculatedData.pesos.tasa_bullet_ganancias_exp_date"}]},[_c('td',[_vm._v("Tasa en dólares bullet con impacto de impuesto a las ganancias a la fecha de vencimiento")]),_c('td',{staticClass:"text-right",class:{
              positive: _vm.calculatedData.pesos.tasa_bullet_ganancias_exp_date < 0,
              negative: _vm.calculatedData.pesos.tasa_bullet_ganancias_exp_date > 0
            }},[_vm._v(" "+_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.pesos.tasa_bullet_ganancias_exp_date).toFixed(2)))+"% ")]),_c('td')]),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.calculatedData.pesos.tasa_bullet_iva_exp_date),expression:"calculatedData.pesos.tasa_bullet_iva_exp_date"}]},[_c('td',[_vm._v("Tasa en dólares bullet con impacto de IVA a la fecha de vencimiento")]),_c('td',{staticClass:"text-right",class:{
              positive: _vm.calculatedData.pesos.tasa_bullet_iva_exp_date < 0,
              negative: _vm.calculatedData.pesos.tasa_bullet_iva_exp_date > 0
            }},[_vm._v(" "+_vm._s(_vm._f("numberToCurrency")(Number(_vm.calculatedData.pesos.tasa_bullet_iva_exp_date).toFixed(2)))+"% ")]),_c('td')])])]),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){_vm.mostrarTabla = !_vm.mostrarTabla}}},[_vm._v("Mostrar parámetros")]),(_vm.mostrarTabla)?_c('table',{staticClass:"table table-sm mt-4"},[_vm._m(1),_c('tbody',[_c('tr',[_c('td',[_vm._v("Cotización dólar a la fecha de vencimiento")]),_c('td',{staticClass:"text-right"},[_vm._v(" $"+_vm._s(_vm._f("numberToCurrency")(_vm.calculatedData.dollars.cotiz_dolar_fecha_vencimiento.toFixed(2)))+" ")])]),_c('tr',[_c('td',[_vm._v("Cotización dólar a la fecha de hoy")]),_c('td',{staticClass:"text-right"},[_vm._v(" $"+_vm._s(_vm._f("numberToCurrency")(_vm.calculatedData.dollars.dollar_today.toFixed(2)))+" ")])]),_c('tr',[_c('td',[_vm._v("Cotización cultivo a la fecha de vencimiento")]),_c('td',{staticClass:"text-right"},[_vm._v(" $"+_vm._s(_vm._f("numberToCurrency")(_vm.calculatedData.pesos.cotiz_fecha_vencimiento.toFixed(2)))+" ")])]),_c('tr',[_c('td',[_vm._v("Cotización cultivo a la fecha de hoy")]),_c('td',{staticClass:"text-right"},[_vm._v("$"+_vm._s(_vm._f("numberToCurrency")(_vm.calculatedData.pesos.cotiz_fecha_hoy.toFixed(2))))])])])]):_vm._e()])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{staticClass:"font-weight-bold",attrs:{"scope":"col"}}),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Pesos")]),_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v("Dólares")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"thead-light"},[_c('tr',[_c('th',{staticClass:"font-weight-bold"},[_vm._v("Otros parámetros")]),_c('th',{staticClass:"font-weight-bold text-center"},[_vm._v("Pesos")])])])
}]

export { render, staticRenderFns }