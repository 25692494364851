<template>
  <card v-if="settings.length">
    <div class="container-fluid">
      <div class="row m-2">
        <div class="pull-left">
          <h4 class="card-title">Parámetros del simulador</h4>
          <p class="card-category">
            Aquí puede cambiar las fechas de vencimiento y las tasas de recargo del simulador.
          </p>
        </div>
      </div>
      <div class="row m-2 mt-4">
        <h4 class="card-title">Valor del dólar futuro</h4>
        <hr class="w-100" />
        <span>Seleccione el mes para la cotización del dólar futuro</span>
        <hr class="w-100" />
        <div class="col-3">
          <VueSelect
            :clearable="false"
            :options="futures"
            ref="VueSelect"
            :value="selectedFutureValue"
            @input="onSelectedFutureChange"
          ></VueSelect>
        </div>
      </div>
      <div class="row m-2 mt-4">
        <h4 class="card-title">Cosechas de tipo gruesa</h4>
        <hr class="w-100" />
        <span>Tasa de recargo para cosechas de tipo gruesa</span>
        <hr class="w-100" />
        <div class="col-4">
          <label>
            <input
              type="checkbox"
              id="frate"
              v-model="formData.statusRateOne.value"
              name="firstrate"
              @change="onFieldChange(formData.statusRateOne)"
            />
            Aplicar tasas a cultivos individuales
          </label>
        </div>
        <template v-if="formData.statusRateOne.value">
          <div class="col-3">
            <fg-input
              class="text-center"
              v-model="formData.soyRate.value"
              type="number"
              label="Tasa de recargo para cosecha de soja"
              placeholder="Tasa de recargo"
              @change="onFieldChange(formData.soyRate)"
            />
            <fg-input
              class="text-center"
              v-model="formData.cornRate.value"
              type="number"
              label="Tasa de recargo para cosecha de maíz"
              placeholder="Tasa de recargo"
              @change="onFieldChange(formData.cornRate)"
            />
          </div>
          <div class="col-3">
            <fg-input
              class="text-center"
              v-model="formData.sunflowerRate.value"
              type="number"
              label="Tasa de recargo para cosecha de girasol"
              placeholder="Tasa de recargo"
              @change="onFieldChange(formData.sunflowerRate)"
            />
          </div>
        </template>
        <template v-else>
          <div class="col-3">
            <fg-input
              class="text-center"
              v-model="formData.thickRate.value"
              type="number"
              label="Tasa de recargo para cosechas de tipo gruesa"
              placeholder="Tasa de recargo"
              @change="onFieldChange(formData.thickRate)"
            />
          </div>
        </template>
        <hr class="w-100" />
        <span>Fecha de finalización del préstamo</span>
        <hr class="w-100" />
        <div class="col-4">
          <label>
            <input
              type="checkbox"
              id="fdate"
              v-model="formData.statusDateOne.value"
              name="firstdate"
              @change="onFieldChange(formData.statusDateOne)"
            />
            Aplicar fecha a cultivos individuales
          </label>
        </div>
        <template v-if="formData.statusDateOne.value">
          <div class="col-3">
            <fg-input
              class="text-center"
              v-model="formData.soyDate.value"
              type="date"
              label="Fecha de vencimiento para cosecha de soja"
              placeholder="Fecha"
              @change="onFieldChange(formData.soyDate)"
            />
            <fg-input
              class="text-center"
              v-model="formData.cornDate.value"
              type="date"
              label="Fecha de vencimiento para cosecha de maíz"
              placeholder="Fecha"
              @change="onFieldChange(formData.cornDate)"
            />
          </div>
        </template>
        <template v-else>
          <div class="col-3">
            <fg-input
              class="text-center"
              v-model="formData.thickDate.value"
              type="date"
              label="Fecha de vencimiento del préstamo"
              placeholder="Fecha"
              @change="onFieldChange(formData.thickDate)"
            />
          </div>
        </template>
      </div>
      <div class="row m-2 mt-4">
        <h4 class="card-title">Cosechas de tipo fina</h4>
        <hr class="w-100" />
        <span>Tasa de recargo para cosechas de tipo fina</span>
        <hr class="w-100" />
        <div class="col-4">
          <label>
            <input
              type="checkbox"
              id="srate"
              v-model="formData.statusRateTwo.value"
              name="secondtrate"
              @change="onFieldChange(formData.statusRateTwo)"
            />
            Aplicar tasas a cultivos individuales
          </label>
        </div>
        <template v-if="formData.statusRateTwo.value">
          <div class="col-3">
            <fg-input
              class="text-center"
              v-model="formData.wheatRate.value"
              type="number"
              label="Tasa de recargo para cosecha de trigo"
              placeholder="Tasa de recargo"
              @change="onFieldChange(formData.wheatRate)"
            />
          </div>
        </template>
        <template v-else>
          <div class="col-3">
            <fg-input
              class="text-center"
              v-model="formData.thinRate.value"
              type="number"
              label="Tasa de recargo para cosechas de tipo fina"
              placeholder="Tasa de recargo"
              @change="onFieldChange(formData.thinRate)"
            />
          </div>
        </template>
        <hr class="w-100" />
        <span>Fecha de finalización del préstamo</span>
        <hr class="w-100" />
        <div class="col-4">
          <label>
            <input
              type="checkbox"
              id="sdate"
              v-model="formData.statusDateTwo.value"
              name="seconddate"
              value="true"
              unchecked-value="false"
              @change="onFieldChange(formData.statusDateTwo)"
            />
            Aplicar fecha a cultivos individuales
          </label>
        </div>
        <template v-if="formData.statusDateTwo.value">
          <div class="col-3">
            <fg-input
              class="text-center"
              v-model="formData.wheatDate.value"
              type="date"
              label="Fecha de vencimiento para para la cosecha de trigo"
              placeholder="Fecha"
              @change="onFieldChange(formData.wheatDate)"
            />
          </div>
        </template>
        <template v-else>
          <div class="col-3">
            <fg-input
              class="text-center"
              v-model="formData.thinDate.value"
              type="date"
              label="Fecha de vencimiento del préstamo"
              placeholder="Fecha"
              @change="onFieldChange(formData.thinDate)"
            />
          </div>
        </template>
      </div>
      <div class="row m-2 mt-4">
        <h4 class="card-title">Cotizaciones de cultivos del día (QQ/U$S)</h4>
        <hr class="w-100" />
        <label>
          <input
            type="checkbox"
            id="cropquotation"
            v-model="formData.statusQuotation.value"
            name="cropQuotation"
            @change="onFieldChange(formData.statusQuotation)"
          />
          Ingresar manualmente las cotizaciones del día de los cultivos (QQ/U$S)
        </label>
        <hr class="w-100" />
        <template v-if="formData.statusQuotation.value">
          <span>Ingrese manualmente las cotizaciones del día de los cultivos (QQ/U$S)</span>
          <hr class="w-100" />
          <div class="col-3">
            <fg-input
              class="text-center"
              v-model="formData.soyQuotation.value"
              type="number"
              label="Cotización del día de la soja (QQ/U$S)"
              placeholder="Cotización"
              @change="onFieldChange(formData.soyQuotation)"
            />
            <fg-input
              class="text-center"
              v-model="formData.cornQuotation.value"
              type="number"
              label="Cotización del día del maíz (QQ/U$S)"
              placeholder="Cotización"
              @change="onFieldChange(formData.cornQuotation)"
            />
          </div>
          <div class="col-3">
            <fg-input
              class="text-center"
              v-model="formData.wheatQuotation.value"
              type="number"
              label="Cotización del día del trigo (QQ/U$S)"
              placeholder="Cotización"
              @change="onFieldChange(formData.wheatQuotation)"
            />
            <fg-input
              class="text-center"
              v-model="formData.sunflowerQuotation.value"
              type="number"
              label="Cotización del día del girasol (QQ/U$S)"
              placeholder="Cotización"
              @change="onFieldChange(formData.sunflowerQuotation)"
            />
          </div>
        </template>
      </div>
      <div class="row align-items-end justify-content-end mb-2">
        <p-button type="primary" @click.native.prevent="saveChanges">Guardar parámetros</p-button>
      </div>
    </div>
  </card>
</template>

<script>
import VueSelect from '@/components/VueSelect';

export default {
  components: {
    VueSelect
  },

  data() {
    return {
      errors: [],
      monthsMapping: {
        0: '01',
        1: '02',
        2: '03',
        3: '04',
        4: '05',
        5: '06',
        6: '07',
        7: '08',
        8: '09',
        9: '10',
        10: '11',
        11: '12'
      },
      settings: [],
      futures: [],
      cropFutures: [],
      formData: {
        statusRateOne: null,
        statusRateTwo: null,
        statusDateOne: null,
        statusDateTwo: null,
        statusQuotation: null,
        selectedFuture: null,
        thickRate: {},
        thinRate: {},
        soyRate: '',
        cornRate: '',
        sunflowerRate: '',
        wheatRate: '',
        thickDate: '',
        thinDate: '',
        soyDate: '',
        cornDate: '',
        wheatDate: '',
        soyQuotation: '',
        cornQuotation: '',
        wheatQuotation: '',
        sunflowerQuotation: ''
      }
    };
  },

  computed: {
    selectedFutureValue() {
      return this.futures.find(future => future.value === Number(this.formData.selectedFuture.value));
    }
  },

  created() {
    this.fetchSettings();
    this.fetchCropFutures();
    this.$api.simulator
      .getAll({
        perPage: 13
      })
      .then(res => {
        this.futures = res.data.futures
          .filter(element => {
            return (
              element.option === 'DLRMATBAROFEX' ||
              (element.option.startsWith('DLR') && this.checkDollarDate(element.option))
            );
          })
          .map(futures => ({
            label: futures.option + '-' + futures.value,
            value: futures.id
          }));
      });
  },

  methods: {
    checkDollarDate(element) {
      return (
        (element.substr(5) == new Date().getFullYear() && element.substr(3, 2) >= new Date().getMonth() + 1) ||
        element.substr(5) > new Date().getFullYear()
      );
    },

    setSettings() {
      this.formData.thickRate = this.getOption('thick_recharge_rate');
      this.formData.thinRate = this.getOption('thin_recharge_rate');
      this.formData.soyRate = this.getOption('soy_recharge_rate');
      this.formData.cornRate = this.getOption('corn_recharge_rate');
      this.formData.wheatRate = this.getOption('wheat_recharge_rate');
      this.formData.sunflowerRate = this.getOption('sunflower_recharge_rate');
      this.formData.selectedFuture = this.getOption('selected_future');
      this.formData.thinDate = this.getOption('thin_crop_date');
      this.formData.thickDate = this.getOption('thick_crop_date');
      this.formData.soyDate = this.getOption('soy_crop_date');
      this.formData.cornDate = this.getOption('corn_crop_date');
      this.formData.wheatDate = this.getOption('wheat_crop_date');
      this.formData.soyQuotation = this.getOption('soy_quotation');
      this.formData.cornQuotation = this.getOption('corn_quotation');
      this.formData.wheatQuotation = this.getOption('wheat_quotation');
      this.formData.sunflowerQuotation = this.getOption('sunflower_quotation');
      this.formData.statusDateOne = this.mapBooleanValue(this.getOption('apply_all_thick_dates'));
      this.formData.statusDateTwo = this.mapBooleanValue(this.getOption('apply_all_thin_dates'));
      this.formData.statusRateOne = this.mapBooleanValue(this.getOption('apply_all_thick_rates'));
      this.formData.statusRateTwo = this.mapBooleanValue(this.getOption('apply_all_thin_rates'));
      this.formData.statusQuotation = this.mapBooleanValue(this.getOption('apply_quotations_manually'));
    },

    fetchSettings() {
      this.$api.settings.getAll({ perPage: 100 }).then(res => {
        this.settings = res.data.settings;
        this.setSettings();
      });
    },

    fetchCropFutures() {
      this.$api.simulator.getAllCropFutures({ perPage: 100 }).then(res => {
        this.cropFutures = res.data.futures;
      });
    },

    getOption(setting) {
      return this.settings.find(s => s.option === setting);
    },

    mapBooleanValue(option) {
      return {
        ...option,
        value: !!Number(option.value)
      };
    },

    onFieldChange(field) {
      field.hasChanged = true;
    },

    onSelectedFutureChange(selectedOption) {
      this.formData.selectedFuture.value = selectedOption.value;
      this.onFieldChange(this.formData.selectedFuture);
    },

    acceptedThickDate(date) {
      date.setDate(date.getDate() + 1);
      const soyRegExp = new RegExp(
        `^soja ros ${this.monthsMapping[date.getMonth()]}\/${date.getFullYear().toString()}`,
        'i'
      );
      let validSoy = this.cropFutures.some(future => soyRegExp.test(future.option));
      const cornRegExp = new RegExp(
        `^maíz ros ${this.monthsMapping[date.getMonth()]}\/${date.getFullYear().toString()}`,
        'i'
      );
      let validCorn = this.cropFutures.some(future => cornRegExp.test(future.option));
      return validSoy && validCorn && (this.isAValidDate(date) || !this.formData.thickDate.hasChanged);
    },

    acceptedSoyDate(date) {
      date.setDate(date.getDate() + 1);
      const regExp = new RegExp(
        `^soja ros ${this.monthsMapping[date.getMonth()]}\/${date.getFullYear().toString()}`,
        'i'
      );
      let valid = this.cropFutures.some(future => regExp.test(future.option));
      return valid && (this.isAValidDate(date) || !this.formData.soyDate.hasChanged);
    },

    acceptedCornDate(date) {
      date.setDate(date.getDate() + 1);
      const regExp = new RegExp(
        `^maíz ros ${this.monthsMapping[date.getMonth()]}\/${date.getFullYear().toString()}`,
        'i'
      );
      let valid = this.cropFutures.some(future => regExp.test(future.option));
      return valid && (this.isAValidDate(date) || !this.formData.cornDate.hasChanged);
    },

    acceptedThinDate(date) {
      date.setDate(date.getDate() + 1);
      const regExp = new RegExp(
        `^trigo ros ${this.monthsMapping[date.getMonth()]}\/${date.getFullYear().toString()}`,
        'i'
      );
      console.log(regExp)
      let valid = this.cropFutures.some(future => regExp.test(future.option));
      return valid && (this.isAValidDate(date) || !this.formData.thinDate.hasChanged);
    },

    isAValidDate(date) {
      return new Date(date) >= new Date();
    },

    isValid() {
      let isValid = true;
      this.errors = [];

      if (
        Number.parseFloat(this.formData.thickRate.value) === 0 ||
        Number.parseFloat(this.formData.thinRate.value) === 0 ||
        this.formData.thinRate.value == '' ||
        this.formData.thickRate.value == ''
      ) {
        isValid = false;
        this.errors.push('Las tasas deben ser completadas.');
      }
      if (
        Number.parseFloat(this.formData.soyRate.value) === 0 ||
        Number.parseFloat(this.formData.cornRate.value) === 0 ||
        Number.parseFloat(this.formData.sunflowerRate.value) === 0 ||
        Number.parseFloat(this.formData.wheatRate.value) === 0 ||
        this.formData.soyRate.value == '' ||
        this.formData.cornRate.value == '' ||
        this.formData.wheatRate.value == '' ||
        this.formData.sunflowerRate.value == ''
      ) {
        isValid = false;
        this.errors.push('Las tasas de recargo de los cultivos deben ser completadas.');
      }

      if (!this.formData.statusDateOne.value && !this.acceptedThickDate(new Date(this.formData.thickDate.value))) {
        isValid = false;
        this.errors.push(
          'No hay futuros de los cultivos de tipo gruesa para la fecha seleccionada o la fecha es anterior a la fecha de hoy'
        );
      }

      if (!this.formData.statusDateTwo.value && !this.acceptedThinDate(new Date(this.formData.thinDate.value))) {
        isValid = false;
        this.errors.push(
          'No hay futuros de los cultivos de tipo fina para la fecha seleccionada o la fecha es anterior a la fecha de hoy'
        );
      }

      if (this.formData.statusDateOne.value && (this.formData.soyDate === '' || this.formData.cornDate === '')) {
        isValid = false;
        this.errors.push('Debe llenar todas las fechas de tipo gruesa.');
      }

      if (
        (this.formData.statusDateOne.value &&
          (this.formData.soyDate != '' || this.formData.cornDate != '') &&
          !this.acceptedSoyDate(new Date(this.formData.soyDate.value))) ||
        !this.acceptedCornDate(new Date(this.formData.cornDate.value))
      ) {
        isValid = false;
        this.errors.push(
          'No hay futuros de los cultivos de tipo gruesa para la fecha seleccionada o la fecha es anterior a la fecha de hoy'
        );
      }

      if (this.formData.statusDateTwo.value && this.formData.wheatDate === '') {
        isValid = false;
        this.errors.push('Debe llenar todas las fechas de tipo fina.');
      }

      if (
        this.formData.statusDateTwo.value &&
        this.formData.wheatDate != '' &&
        !this.acceptedThinDate(new Date(this.formData.wheatDate.value))
      ) {
        isValid = false;
        this.errors.push(
          'No hay futuros de los cultivos de tipo fina para la fecha seleccionada o la fecha es anterior a la fecha de hoy'
        );
      }
      return isValid;
    },

    saveChanges() {
      if (this.isValid()) {
        let changedSettings = Object.values(this.formData).filter(s => s.hasChanged);
        this.$api.settings.updateMany(changedSettings);
        this.$swal({
          title: 'Éxito!',
          text: 'Parámetros modificados exitosamente.',
          type: 'success',
          footer: ''
        });
      } else {
        if (this.errors.length > 0) {
          let errorText = '<p>Ocurrió un error de validación.</p>';
          let errorList = '<ul style="list-style-type:none">';
          this.errors.forEach(e => {
            errorList += '<li>- ' + e + '</li>';
          });
          errorList += '</ul>';
          this.$swal({
            title: 'Error!',
            html: errorText + errorList,
            type: 'error'
          });
        }
      }
    }
  }
};
</script>
