<template>
  <card v-if="newParams">
    <div v-if="futureDollars.length" class="alert alert-warning alert-with-icon">
      <span data-notify="message">
        No se encontró dólar futuro para algunas de las fechas seleccionadas. Las fechas utilizadas fueron:
      </span>
      <div v-for="f in futureDollars" :key="f">
        <span data-notify="message" class="ml-4">* {{ f }}</span>
      </div>
    </div>
    <div class="card-header mb-2 align-items-center">
      <slot name="header">
        <div>
          <h4 class="card-title mb-2">{{ title }}</h4>
          <p>Asegurado: {{ client }}</p>
          <p>Cotización: {{ negotiationId }}</p>
          <div v-if="!hideEdit">
            <p>Aquí puede cambiar los parámetros del simulador para esta cotización.</p>
            <button v-if="editMode" class="btn btn-light m-2" @click="changeState">Cancelar</button>
            <button v-else class="btn btn-info m-2" @click="changeState">Editar</button>
            <button v-if="editMode" class="btn btn-primary m-2" @click="[changeStateSave()]">Guardar</button>
          </div>
        </div>
        <button class="btn btn-link" :class="{ openCard: open }" @click="open = !open">
          <i class="ti-angle-down"></i>
        </button>
      </slot>
    </div>
    <div v-show="open && !hideEdit" class="card-body" :class="{ openCardBody: open }">
      <div class="container">
        <div class="row">
          <div class="col grid">
            <div class="grid-row headings">
              <div class="grid-col">
                <p><strong>Cosechas de tipo gruesa</strong></p>
              </div>
              <div class="grid-col">
                <p><strong>Cosechas de tipo fina</strong></p>
              </div>
            </div>
            <div class="grid-row">
              <!-- tasas de recargo gruesa -->
              <div class="grid-col">
                <div v-if="editMode">
                  <label>
                    <input type="checkbox" id="frate" v-model="newParams.applyAllThickRates" name="firstrate" />
                    Aplicar tasas a cultivos individuales
                  </label>
                </div>
                <template v-if="newParams.applyAllThickRates">
                  <div>
                    <div>
                      <p class="h6 mb-2">Tasa Recargo Soja</p>
                      <fg-input
                        class="text-center"
                        v-model="newParams.soyRate"
                        type="number"
                        placeholder="Tasa recargo soja"
                        v-if="editMode"
                      ></fg-input>
                      <p v-else>{{ newParams.soyRate }}%</p>
                    </div>
                    <div>
                      <p class="h6 mb-2">Tasa Recargo Maiz</p>
                      <fg-input
                        class="text-center"
                        v-model="newParams.cornRate"
                        type="number"
                        placeholder="Tasa recargo maíz"
                        v-if="editMode"
                      ></fg-input>
                      <p v-else>{{ newParams.cornRate }}%</p>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <p class="h6 mb-2">Tasa Recargo Cosechas Gruesas</p>
                    <fg-input
                      class="text-center"
                      v-model="newParams.thickRate"
                      type="number"
                      placeholder="Tasa recargo cosecha gruesa"
                      v-if="editMode"
                    ></fg-input>
                    <p v-else>{{ newParams.thickRate }}%</p>
                  </div>
                </template>
              </div>
              <!-- tasas de recargo fina -->
              <div class="grid-col">
                <div v-if="editMode">
                  <label>
                    <input type="checkbox" id="frate" v-model="newParams.applyAllThinRates" name="secondtrate" />
                    Aplicar tasas a cultivos individuales
                  </label>
                </div>
                <template v-if="newParams.applyAllThinRates">
                  <div>
                    <p class="h6 mb-2">Tasa Recargo Trigo</p>
                    <fg-input
                      class="text-center"
                      v-model="newParams.wheatRate"
                      type="number"
                      placeholder="Tasa recargo trigo"
                      v-if="editMode"
                    ></fg-input>
                    <p v-else>{{ newParams.wheatRate }}%</p>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <p class="h6 mb-2">Tasa Recargo Cosechas Finas</p>
                    <fg-input
                      class="text-center"
                      v-model="newParams.thinRate"
                      type="number"
                      placeholder="Tasa recargo cosecha fina"
                      v-if="editMode"
                    ></fg-input>
                    <p v-else>{{ newParams.thinRate }}%</p>
                  </div>
                </template>
              </div>
            </div>
            <div class="grid-row">
              <!-- fechas de vencimiento gruesa -->
              <div class="grid-col">
                <div v-if="editMode">
                  <label>
                    <input type="checkbox" id="fdate" v-model="newParams.applyAllThickDates" name="firstdate" />
                    Aplicar fecha a cultivos individuales
                  </label>
                </div>
                <template v-if="newParams.applyAllThickDates">
                  <div>
                    <div class="row">
                      <div v-if="editMode" class="col">
                        <p class="h6 mb-2">Fecha vencimiento Soja</p>
                        <fg-input
                          class="text-center"
                          v-model="newParams.soyCropDate"
                          type="date"
                          placeholder="Fecha finalización préstamo para soja"
                          @change="newParams.soyDollarDate = $event"
                        ></fg-input>
                      </div>
                      <div v-if="editMode" class="col">
                        <p class="h6 mb-2">Fecha vencimiento de dólar Soja</p>
                        <fg-input
                          class="text-center"
                          v-model="$v.newParams.soyDollarDate.$model"
                          :is-valid="!$v.newParams.soyDollarDate.$error"
                          :errorMessage="'Fecha inválida, debe ser menor a un año a partir de hoy'"
                          type="date"
                          placeholder=""
                        ></fg-input>
                      </div>
                      <div v-else>
                        <p>{{ newParams.soyCropDate }}</p>
                        <p>{{ newParams.soyDollarDate }}</p>
                      </div>
                    </div>
                    <div>
                      <div class="row">
                        <div v-if="editMode" class="col">
                          <p class="h6 mb-2">Fecha vencimiento Maíz</p>
                          <fg-input
                            class="text-center"
                            v-model="newParams.cornCropDate"
                            type="date"
                            placeholder="Fecha finalización préstamo para maíz"
                            @change="newParams.cornDollarDate = $event"
                          ></fg-input>
                        </div>
                        <div v-if="editMode" class="col">
                          <p class="h6 mb-2">Fecha vencimiento de dólar Maíz</p>
                          <fg-input
                            class="text-center"
                            v-model="$v.newParams.cornDollarDate.$model"
                            :is-valid="!$v.newParams.cornDollarDate.$error"
                            :errorMessage="'Fecha inválida, debe ser menor a un año a partir de hoy'"
                            type="date"
                            placeholder=""
                          ></fg-input>
                        </div>
                        <div v-else>
                          <p>{{ newParams.cornCropDate }}</p>
                          <p>{{ newParams.cornDollarDate }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="row">
                    <div v-if="editMode" class="col">
                      <p class="h6 mb-2">Fecha vto. Cosechas Gruesas</p>
                      <fg-input
                        class="text-center"
                        v-model="newParams.thickCropDate"
                        type="date"
                        placeholder="Fecha finalización préstamo para cosecha gruesa"
                        @change="newParams.thickDollarDate = $event"
                      ></fg-input>
                    </div>
                    <div v-if="editMode" class="col">
                      <p class="h6 mb-2">Fecha vto. de dólar Cosecha Gruesa</p>
                      <fg-input
                        class="text-center"
                        v-model="$v.newParams.thickDollarDate.$model"
                        :is-valid="!$v.newParams.thickDollarDate.$error"
                        :errorMessage="'Fecha inválida, debe ser menor a un año a partir de hoy'"
                        type="date"
                        placeholder=""
                      ></fg-input>
                    </div>
                    <div v-else>
                      <p>{{ newParams.thickCropDate }}</p>
                      <p>{{ newParams.thickDollarDate }}</p>
                    </div>
                  </div>
                </template>
              </div>
              <!-- fechas de vencimiento fina -->
              <div class="grid-col">
                <div v-if="editMode">
                  <label>
                    <input type="checkbox" id="fdate" v-model="newParams.applyAllThinDates" name="firstdate" />
                    Aplicar fecha a cultivos individuales
                  </label>
                </div>
                <template v-if="newParams.applyAllThinDates">
                  <div class="row">
                    <div v-if="editMode" class="col">
                      <p class="h6 mb-2">Fecha vencimiento Trigo</p>
                      <fg-input
                        class="text-center"
                        v-model="newParams.wheatCropDate"
                        type="date"
                        placeholder="Fecha finalización préstamo para trigo"
                        @change="newParams.wheatDollarDate = $event"
                      ></fg-input>
                    </div>
                    <div v-if="editMode" class="col">
                      <p class="h6 mb-2">Fecha vto. de dólar Trigo</p>
                      <fg-input
                        class="text-center"
                        type="date"
                        placeholder=""
                        v-model="$v.newParams.wheatDollarDate.$model"
                        :is-valid="!$v.newParams.wheatDollarDate.$error"
                        :errorMessage="'Fecha inválida, debe ser menor a un año a partir de hoy'"
                      ></fg-input>
                    </div>
                    <div v-else>
                      <p>{{ newParams.wheatCropDate }}</p>
                      <p>{{ newParams.wheatDollarDate }}</p>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="row">
                    <div v-if="editMode" class="col">
                      <p class="h6 mb-2">Fecha vto. Cosechas Finas</p>
                      <fg-input
                        class="text-center"
                        v-model="newParams.thinCropDate"
                        type="date"
                        placeholder="Fecha finalización préstamo para cosecha fina"
                        @change="newParams.thinDollarDate = $event"
                      ></fg-input>
                    </div>
                    <div v-if="editMode" class="col">
                      <p class="h6 mb-2">Fecha vto. de dólar Cosecha Fina</p>
                      <fg-input
                        class="text-center"
                        v-model="$v.newParams.thinDollarDate.$model"
                        :is-valid="!$v.newParams.thinDollarDate.$error"
                        :errorMessage="'Fecha inválida, debe ser menor a un año a partir de hoy'"
                        type="date"
                        placeholder=""
                      ></fg-input>
                    </div>
                    <div v-else>
                      <p>{{ newParams.thinCropDate }}</p>
                      <p>{{ newParams.thinDollarDate }}</p>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import { validateFutureDate } from '@/utils/validators';

export default {
  props: {
    dailyParameters: {
      required: true,
      type: Object
    },
    dollars: {
      type: Array,
      required: true
    },
    params: {
      type: Object,
      required: true
    },
    futures: {
      type: Object,
      required: true
    },
    client: {
      type: String,
      required: true
    },

    hideEdit: {
      type: Boolean,
      default: false
    },
    negotiationId: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      title: 'Parámetros de la simulación',
      newParams: null,
      editMode: false,
      open: false,
      monthsMapping: {
        0: 'ENE',
        1: 'FEB',
        2: 'MAR',
        3: 'ABR',
        4: 'MAY',
        5: 'JUN',
        6: 'JUL',
        7: 'AGO',
        8: 'SEP',
        9: 'OCT',
        10: 'NOV',
        11: '(DIS|DIC)'
      },
      cropFutures: [],
      futureDollars: [],
      errorMessage: ''
    };
  },

  computed: {
    getDollar() {
      const selected = this.dollars.find(d => d.id === Number(this.newParams.selectedFutureValue));
      return selected ? selected.value : '';
    }
  },

  validations: {
    newParams: {
      wheatDollarDate: {
        required: function (value) {
          return this.newParams.applyAllThinDates ? validateFutureDate(value) : true;
        }
      },
      thinDollarDate: {
        required: function (value) {
          return !this.newParams.applyAllThinDates ? validateFutureDate(value) : true;
        }
      },
      soyDollarDate: {
        required: function (value) {
          return this.newParams.applyAllThickDates ? validateFutureDate(value) : true;
        }
      },
      cornDollarDate: {
        required: function (value) {
          return this.newParams.applyAllThickDates ? validateFutureDate(value) : true;
        }
      },
      thickDollarDate: {
        required: function (value) {
          return !this.newParams.applyAllThickDates ? validateFutureDate(value) : true;
        }
      }
    }
  },

  mounted() {
    this.newParams = this.params;
    this.datesNotFound();
  },

  created() {
    this.fetchCropFutures();
  },

  methods: {
    fetchCropFutures() {
      this.$api.simulator.getAllCropFutures({ perPage: 100 }).then(res => {
        this.cropFutures = res.data.futures;
      });
    },

    changeState() {
      this.editMode = !this.editMode;
      this.open = !this.open;
    },

    changeStateSave() {
      if (this.validate()) {
        this.editMode = false;
        this.$swal({
          title: 'Éxito!',
          text: 'Parámetros modificados exitosamente.',
          type: 'success',
          footer: ''
        });
        this.$emit('parameters', this.newParams);
      } else {
        console.log('inválido');
      }
    },

    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },

    datesNotFound() {
      //Thick crops
      if (this.newParams.applyAllThickDates) {
        this.futures?.Maíz?.futureDollarDate
          ? this.futureDollars.push('Maíz' + ': ' + this.futures.Maíz.futureDollarDate)
          : null;
        this.futures?.Soja?.futureDollarDate
          ? this.futureDollars.push('Soja' + ': ' + this.futures.Soja.futureDollarDate)
          : null;
      } else {
        this.futures?.Maíz?.futureDollarDate
          ? this.futureDollars.push('Gruesa' + ': ' + this.futures.Maíz.futureDollarDate)
          : null;
      }

      //Thin crops
      if (this.newParams.applyAllThinDates) {
        this.futures?.Trigo?.futureDollarDate
          ? this.futureDollars.push('Trigo' + ': ' + this.futures.Trigo.futureDollarDate)
          : null;
      } else {
        this.futures?.Trigo?.futureDollarDate
          ? this.futureDollars.push('Fina' + ': ' + this.futures.Trigo.futureDollarDate)
          : null;
      }
    }
  }
};
</script>

<style>
.openCard {
  transform: rotate(180deg);
}

.openCardBody {
  transition: all;
  transition-timing-function: ease-in;
}

.col.grid {
  flex-basis: 30%;
}
.grid {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);
}
.grid-row {
  display: contents;
}
.grid-row.headings .grid-col {
  height: 40px;
  border-top: 0;
}
.grid-col {
  border-top: 1px solid #f4f3ef;
  padding-top: 10px;
}
</style>
