<template>
  <div class="tabla-contenedor" v-if="calculatedData">
    <div class="w-100">
      <table class="table w-100">
        <thead class="thead-light">
          <tr>
            <th scope="col" class="font-weight-bold"></th>
            <th scope="col" class="text-center">Pesos</th>
            <th scope="col" class="text-center">Dólares</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Cuota total</td>
            <td class="text-right">${{ Number(calculatedData.pesos.total).toFixed(2) | numberToCurrency }}</td>
            <td class="text-right">USD {{ Number(calculatedData.dollars.total).toFixed(2) | numberToCurrency }}</td>
          </tr>
          <tr>
            <td>Cuota total en pesos según cotización futuro:</td>
            <td class="text-right"></td>
            <td class="text-right">${{ Number(calculatedData.dollars.total_pesos).toFixed(2) | numberToCurrency }}</td>
          </tr>
          <tr>
            <td>TNA</td>
            <td class="text-right">{{ Number(calculatedData.pesos.tna_pesos).toFixed(2) | numberToCurrency }}%</td>
            <td class="text-right">{{ Number(calculatedData.dollars.tna_dollars).toFixed(2) | numberToCurrency }}%</td>
          </tr>
          <tr>
            <td>QQ a la fecha de hoy</td>
            <td class="text-right">{{ Number(calculatedData.pesos.qq_hoy).toFixed(2) | numberToCurrency }} QQ</td>
            <td class="text-right">{{ Number(calculatedData.dollars.qq_hoy).toFixed(2) | numberToCurrency }} QQ</td>
          </tr>
          <tr>
            <td>QQ a la fecha de vencimiento</td>
            <td class="text-right">
              {{ Number(calculatedData.pesos.qq_fecha_vencimiento).toFixed(2) | numberToCurrency }} QQ
            </td>
            <td class="text-right">
              {{ Number(calculatedData.dollars.qq_fecha_vencimiento).toFixed(2) | numberToCurrency }} QQ
            </td>
          </tr>
          <tr>
            <td>Tasa bullet a la fecha de vencimiento en QQ</td>
            <td
              class="text-right"
              :class="{
                positive: calculatedData.pesos.tasa_bullet_fecha_vencimiento < 0,
                negative: calculatedData.pesos.tasa_bullet_fecha_vencimiento > 0
              }"
            >
              {{ Number(calculatedData.pesos.tasa_bullet_fecha_vencimiento).toFixed(2) | numberToCurrency }}%
            </td>
            <td
              class="text-right"
              :class="{
                positive: calculatedData.dollars.tasa_bullet_fecha_vencimiento < 0,
                negative: calculatedData.dollars.tasa_bullet_fecha_vencimiento > 0
              }"
            >
              {{ Number(calculatedData.dollars.tasa_bullet_fecha_vencimiento).toFixed(2) | numberToCurrency }}%
            </td>
          </tr>
          <tr v-show="calculatedData.pesos.ahorro_ganancias">
            <td>Ahorro impuestos a las ganancias (potencial)</td>
            <td class="text-right">
              {{ Number(calculatedData.pesos.ahorro_ganancias).toFixed(2) | numberToCurrency }} QQ
            </td>
            <td class="text-right">{{ Number(calculatedData.dollars.ahorro_ganancias).toFixed(2) | numberToCurrency }} QQ</td>
          </tr>
          <tr v-show="calculatedData.pesos.tasa_bullet_ganancias">
            <td>Tasa bullet con impacto ganancias</td>
            <td
              class="text-right"
              :class="{
                positive: calculatedData.pesos.tasa_bullet_ganancias < 0,
                negative: calculatedData.pesos.tasa_bullet_ganancias > 0
              }"
            >
              {{ Number(calculatedData.pesos.tasa_bullet_ganancias).toFixed(2) | numberToCurrency }}%
            </td>
            <td
              class="text-right"
              :class="{
                positive: calculatedData.dollars.tasa_bullet_ganancias < 0,
                negative: calculatedData.dollars.tasa_bullet_ganancias > 0
              }"
            >
              {{ Number(calculatedData.dollars.tasa_bullet_ganancias).toFixed(2) | numberToCurrency }}%
            </td>
          </tr>
          <tr v-show="calculatedData.pesos.ahorro_iva">
            <td>Ahorro IVA </td>
            <td class="text-right">{{ Number(calculatedData.pesos.ahorro_iva).toFixed(2) | numberToCurrency }} QQ</td>
             <td class="text-right">{{ Number(calculatedData.dollars.ahorro_iva).toFixed(2) | numberToCurrency }} QQ</td>
          </tr>
          <tr v-show="calculatedData.pesos.tasa_bullet_iva">
            <td>Tasa bullet con impacto IVA</td>
            <td
              class="text-right"
              :class="{
                positive: calculatedData.pesos.tasa_bullet_iva < 0,
                negative: calculatedData.pesos.tasa_bullet_iva > 0
              }"
            >
              {{ Number(calculatedData.pesos.tasa_bullet_iva).toFixed(2) | numberToCurrency }}%
            </td>
            <td
              class="text-right"
              :class="{
                positive: calculatedData.dollars.tasa_bullet_iva < 0,
                negative: calculatedData.dollars.tasa_bullet_iva > 0
              }"
            >
              {{ Number(calculatedData.dollars.tasa_bullet_iva).toFixed(2) | numberToCurrency }}%
            </td>
          </tr>
          <tr v-show="calculatedData.pesos.cotiz_fecha_vencimiento">
            <td>Grano futuro</td>
            <td class="text-right">
              $ {{ Number(calculatedData.pesos.cotiz_fecha_vencimiento).toFixed(2) | numberToCurrency }}
            </td>
            <td></td>
          </tr>
          <tr v-show="calculatedData.pesos.dollars_today">
            <td>Dólares hoy</td>
            <td class="text-right">
              USD {{ Number(calculatedData.pesos.dollars_today).toFixed(2) | numberToCurrency }}
            </td>
            <td></td>
          </tr>
          <tr v-show="calculatedData.pesos.dollars_exp_date">
            <td>Dólares a la fecha de vencimiento</td>
            <td class="text-right">
              USD {{ Number(calculatedData.pesos.dollars_exp_date).toFixed(2) | numberToCurrency }}
            </td>
            <td></td>
          </tr>
          <tr v-show="calculatedData.pesos.tasa_bullet_exp_date">
            <td>Tasa en dólares bullet a la fecha de vencimiento</td>
            <td
              class="text-right"
              :class="{
                positive: calculatedData.pesos.tasa_bullet_exp_date < 0,
                negative: calculatedData.pesos.tasa_bullet_exp_date > 0
              }"
            >
               {{ Number(calculatedData.pesos.tasa_bullet_exp_date).toFixed(2) | numberToCurrency }}%
            </td>
            <td></td>
          </tr>
          <tr v-show="calculatedData.pesos.tasa_bullet_ganancias_exp_date">
            <td>Tasa en dólares bullet con impacto de impuesto a las ganancias a la fecha de vencimiento</td>
            <td
              class="text-right"
              :class="{
                positive: calculatedData.pesos.tasa_bullet_ganancias_exp_date < 0,
                negative: calculatedData.pesos.tasa_bullet_ganancias_exp_date > 0
              }"
            >
               {{ Number(calculatedData.pesos.tasa_bullet_ganancias_exp_date).toFixed(2) | numberToCurrency }}%
            </td>
            <td></td>
          </tr>
          <tr v-show="calculatedData.pesos.tasa_bullet_iva_exp_date">
            <td>Tasa en dólares bullet con impacto de IVA a la fecha de vencimiento</td>
            <td
              class="text-right"
              :class="{
                positive: calculatedData.pesos.tasa_bullet_iva_exp_date < 0,
                negative: calculatedData.pesos.tasa_bullet_iva_exp_date > 0
              }"
            >
               {{ Number(calculatedData.pesos.tasa_bullet_iva_exp_date).toFixed(2) | numberToCurrency }}%
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <button class="btn btn-primary" @click="mostrarTabla = !mostrarTabla">Mostrar parámetros</button>
      <table class="table table-sm mt-4" v-if="mostrarTabla">
        <thead class="thead-light">
          <tr>
            <th class="font-weight-bold">Otros parámetros</th>
            <th class="font-weight-bold text-center">Pesos</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Cotización dólar a la fecha de vencimiento</td>
            <td class="text-right">
              ${{ calculatedData.dollars.cotiz_dolar_fecha_vencimiento.toFixed(2) | numberToCurrency }}
            </td>
          </tr>
            <tr>
            <td>Cotización dólar a la fecha de hoy</td>
            <td class="text-right">
              ${{ calculatedData.dollars.dollar_today.toFixed(2) | numberToCurrency }}
            </td>
          </tr>
          <tr>
            <td>Cotización cultivo a la fecha de vencimiento</td>
            <td class="text-right">
              ${{ calculatedData.pesos.cotiz_fecha_vencimiento.toFixed(2) | numberToCurrency }}
            </td>
          </tr>
          <tr>
            <td>Cotización cultivo a la fecha de hoy</td>
            <td class="text-right">${{ calculatedData.pesos.cotiz_fecha_hoy.toFixed(2) | numberToCurrency }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    calculatedData: {
      required: true,
      type: Object
    }
  },

  filters: {
    numberToCurrency(value) {
      if (!value) return '0.00';
      const intPart = Math.trunc(value);
      const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
      let floatPart = '.00';
      const valueArray = value.toString().split('.');
      if (valueArray.length === 2) {
        floatPart = valueArray[1].toString();
        return intPartFormat + '.' + floatPart;
      }
      return intPartFormat + floatPart;
    }
  },

  data() {
    return {
      mostrarTabla: false
    };
  },

  methods: {
    formatearFecha(fecha) {
      const meses = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre'
      ];

      const fechaObj = new Date(fecha);
      const mes = meses[fechaObj.getMonth()];
      const anio = fechaObj.getFullYear();

      return `${mes} ${anio}`;
    }
  }
};
</script>

<style scoped>
/* Tablas */
.tabla-contenedor {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td,
thead,
tbody,
table {
  border: 1px solid #dee2e6 !important;
  padding: 8px;
  text-align: left;
}

.positive {
  color: green;
}

.negative {
  color: red;
}
</style>
