<template>
  <div class="campo-form">
    <div class="row">
      <div class="col-md-6">
        <fg-input
          cy-id="reference-risk"
          type="text"
          label="Referencia"
          placeholder="Referencia del riesgo"
          v-model="formdata.description"
          :disabled="isSimpleNegotiation"
        />
      </div>
      <div class="col-md-6" v-if="!isDoubleCrop">
        <div class="form-group">
          <label>Departamento</label>
          <VueSelect
            v-if="isSimpleNegotiation"
            cy-id="state-risk"
            placeholder="Seleccione departamento"
            :disabled="disabled"
            :clearable="false"
            :options="zoneOptions"
            :class="{ 'select-invalid': $v.formdata.zone.$error }"
            :value="getSimpleNegotiationZone"
            @input="setZone"
            ref="VueSelect"
          >
            <span slot="no-options">No se encontró el departamento.</span>
          </VueSelect>

          <VueSelect
            v-else
            cy-id="state-risk"
            placeholder="Seleccione departamento"
            :clearable="false"
            :options="zoneOptions"
            :class="{ 'select-invalid': $v.formdata.zone.$error }"
            v-model="riskZone"
            ref="VueSelect"
          >
            <span slot="no-options">No se encontró el departamento.</span>
          </VueSelect>
          <small class="text-invalid" v-if="$v.formdata.zone.$error" v-text="'Departamento inválido'" />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label v-if="!isDoubleCrop">Cultivo</label>
          <label v-if="isDoubleCrop">Doble Cultivo</label>
          <select
            cy-id="crop-risk"
            class="custom-select"
            :class="{ 'select-invalid-error': $v.formdata.crop.$error }"
            v-model.trim="$v.formdata.crop.$model"
            :disabled="isSimpleNegotiation"
          >
            <option v-for="crop in filteredCrops" :key="crop.id" :value="crop">
              {{ crop.description }}
            </option>
          </select>
          <small class="text-invalid" v-if="$v.formdata.crop.$error" v-text="'Cultivo inválido'" />
        </div>
      </div>
      <div class="col-md-3">
        <fg-input
          type="number"
          v-if="!isDoubleCrop"
          min="1"
          cy-id="hectares-risk"
          label="Hectáreas"
          placeholder="Cantidad de hectáreas"
          v-model="$v.formdata.numberOfHectares.$model"
          :error-message="'Cantidad de hectáreas inválida'"
          :is-valid="!$v.formdata.numberOfHectares.$error"
          :disabled="disabled && isSimpleNegotiation"
        />
      </div>
      <div class="col-md-3">
        <fg-input
          type="number"
          min="0"
          cy-id="hectares-value-risk"
          label="Valor HA"
          placeholder="Valor por hectárea"
          v-model.trim="$v.formdata.hectarePrice.$model"
          :disabled="disabled && isSimpleNegotiation"
          :error-message="'Precio de hectáreas inválido'"
          :is-valid="!$v.formdata.hectarePrice.$error"
        />
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label>Unidad</label>
          <select
            cy-id="currency-risk"
            class="custom-select"
            :class="{ 'select-invalid-error': $v.formdata.hectarePriceCurrencyId.$error }"
            v-model.trim="$v.formdata.hectarePriceCurrencyId.$model"
            :disabled="disabled || isDoubleCrop"
          >
            <option :value="constants.PESO">$</option>
            <option :value="constants.QUINTAL">QQ</option>
            <option :value="constants.DOLLAR">USD</option>
          </select>
          <small class="text-invalid" v-if="$v.formdata.hectarePriceCurrencyId.$error" v-text="'Moneda inválida'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSelect from '@/components/VueSelect';
import constants from '@/plugins/constants.js';
import { mapActions, mapGetters } from 'vuex';
import { required, decimal, requiredIf, minValue } from 'vuelidate/lib/validators';

export default {
  name: 'RiskForm',
  components: {
    VueSelect
  },
  props: {
    isSimpleNegotiation: {
      type: Boolean,
      default: false
    },
    isUpdateNegotiation: {
      type: Boolean,
      default: false
    },
    isDoubleCrop: {
      type: Boolean,
      default: false
    },
    zone: {
      type: Object,
      default: null
    },
    currency: {
      type: [Number, String],
      default: null
    },
    hectares: {
      type: [Number, String],
      default: null
    },
    risk: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    state: {
      type: String,
      default: null
    },
    doubleCrop: {
      type: Object,
      default: null
    },
    doubleCropId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      formdata: {
        description: '',
        numberOfHectares: '',
        hectarePriceCurrencyId: this.currency,
        hectarePrice: '',
        zone: null,
        crop: null,
        discount: null,
        doubleCrop: {}
      },
      constants: constants
    };
  },
  computed: {
    ...mapActions(['fetchZones', 'fetchCrops']),
    ...mapGetters({ crops: 'getCrops', zones: 'getZones' }),
    zoneOptions() {
      return this.zones.map(zone => ({
        label: `${zone.description} ( ${zone.province.name} )`,
        id: zone.id
      }));
    },
    filteredCrops() {
      let fc = this.crops;
      if (this.isDoubleCrop) {
        fc = fc.filter(c => c.harvest === constants.THICK_HARVEST.key);
      }
      return fc;
    },
    getSimpleNegotiationZone() {
      return {
        label: `${this.risk?.zone.description} ( ${this.risk.zone?.province.name} )`,
        value: this.risk?.zone.id | null
      };
    },
    riskZone: {
      set(selected) {
        if (selected.id && selected.id !== this.formdata.zone?.id) {
          this.formdata.zone = this.zones.find(zone => zone.id === selected.id);
        }
      },
      get() {
        return this.zoneOptions.find(zone => zone.id === this.formdata?.zone?.id) ?? null;
      }
    }
  },

  mounted() {
    this.verifiedCropsZones();
    this.setDefaultValues();
  },
  validations: {
    formdata: {
      numberOfHectares: {
        required: requiredIf(function (e) {
          return !this.isDoubleCrop;
        }),
        minValue: minValue(1)
      },
      hectarePrice: {
        required,
        decimal
      },
      zone: {
        required: requiredIf(function () {
          return !this.isDoubleCrop;
        })
      },
      crop: {
        required
      },
      hectarePriceCurrencyId: {
        required: minValue(1)
      }
    }
  },
  methods: {
    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    verifiedCropsZones() {
      if (this.zones.length === 0 || this.crops.length === 0) {
        this.fetchZones;
        this.fetchCrops;
      }
    },
    setDefaultValues() {
      if (!this.risk && this.isDoubleCrop && this.doubleCrop) {
        this.formdata.description = this.doubleCrop?.description;
        this.formdata.hectarePrice = this.doubleCrop?.hectarePrice;
        this.formdata.crop = this.crops.find(crop => crop.id === this.doubleCrop.cropId);
        this.formdata.doubleCrop.crop = this.doubleCrop.cropId;
      }

      if (this.risk) {
        this.formdata.hectarePriceCurrencyId = this.risk.hectarePriceCurrencyId
          ? this.risk.hectarePriceCurrencyId
          : this.currency;
        this.formdata.numberOfHectares = this.risk.numberOfHectares;
        this.formdata.zone = this.risk.zone;
        this.formdata.id = this.risk.id;
        this.formdata.crop = this.risk.crop;
        this.formdata.discount = this.risk.discount;

        const doubleCropData = this.risk.doubleCropRisk ? this.risk.doubleCropRisk : this.risk.doubleCrop;

        if (doubleCropData) {
          this.formdata.doubleCrop = doubleCropData;
        }
        if (this.isDoubleCrop) {
          this.formdata.doubleCrop = doubleCropData;
          this.formdata.description = this.risk.doubleCrop?.description;
          this.formdata.hectarePrice = this.risk.doubleCrop?.hectarePrice;
          this.formdata.crop = this.crops.find(crop => crop.id === this.risk.doubleCrop.cropId);
          this.formdata.doubleCrop.crop = doubleCropData.cropId;
        } else {
          this.formdata.description = this.risk.description;
          this.formdata.hectarePrice = this.risk.hectarePrice;
        }
      } else {
        this.formdata.hectarePriceCurrencyId = this.currency;
      }
    },
    setZone(option) {
      if (option.id !== this.formdata.zone?.id) {
        this.formdata.zone = this.isSimpleNegotiation ? this.risk.zone : this.zones.find(zone => zone.id === option.id);
      }
    }
  },
  watch: {
    currency: {
      handler(newVal, oldVal) {
        this.formdata.hectarePriceCurrencyId = newVal;
      }
    },
    formdata: {
      handler(nuevo, viejo) {
        this.$emit('cambianDatos', nuevo);
      },
      deep: true
    }
  }
};
</script>
<style scoped>
.select-invalid ::v-deep .vselect-dropdown-toggle {
  border-color: #dc3545;
  border-radius: 5px;
}
.select-invalid-error {
  border-color: #dc3545;
  border-radius: 5px;
}
.text-invalid {
  color: #dc3545;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
}
</style>
